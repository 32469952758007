
import { defineComponent } from 'vue'
export default defineComponent({
  props: ['params'],
  setup (props) {
    return { 
      action: props.params.action
    }
  }
})
