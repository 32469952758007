import { createApi } from '@/plugin/Api'
import { defineStore } from 'pinia'
import { IdentityContextService, ImpersonateUserCommand } from '@/generated/financialAid.api.clients'

const service = new IdentityContextService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))

export const useIdentityContextStore = defineStore('identity-context-store', {
  state: () => {
    return {
    }
  },
  getters: {
  },
  actions: {
    async impersonateUser() {
      const userName = window.prompt('Enter User Name')

      if (userName) {
        await service.impersonateUser(new ImpersonateUserCommand({ username: userName }))
        window.location.assign(process.env.VUE_APP_PORTAL_REDIRECT_URL)
      }
    },
    async endImpersonation() {
      await service.endImpersonation()
      window.location.assign(process.env.VUE_APP_PORTAL_REDIRECT_URL)
    }
  }  
})
