
import { defineComponent } from 'vue'
import { UserTypeEnum } from '@/common/enumerations'

export default defineComponent({
  props: {
    userType: { type: String, required: true }
  },
  setup(props) {
    return {
      UserTypeEnum
    }
  }
})
