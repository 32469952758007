import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  for: "question-1",
  class: "mb-2"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["id", "onUpdate:modelValue"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "text-danger text-end p-2" }
const _hoisted_10 = { class: "col-12 my-3" }
const _hoisted_11 = { class: "d-flex" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.localData, (cq, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-12 mt-3",
        key: index
      }, [
        _createElementVNode("h3", _hoisted_2, "Question #" + _toDisplayString(index + 1), 1),
        (_ctx.pageState.isViewMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.state.potentialSecurityQuestions?.find(q => q.id === _ctx.state.localData[index].securityQuestionId)?.prompt), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _withDirectives(_createElementVNode("select", {
                id: `question-${index}`,
                "onUpdate:modelValue": ($event: any) => (_ctx.state.localData[index].securityQuestionId = $event),
                class: "form-select"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionSubset(index), (q) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: q.id,
                    value: q.id
                  }, _toDisplayString(q.prompt), 9, _hoisted_6))
                }), 128))
              ], 8, _hoisted_5), [
                [_vModelSelect, _ctx.state.localData[index].securityQuestionId]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control mt-1",
                "onUpdate:modelValue": ($event: any) => (_ctx.state.localData[index].answer = $event)
              }, null, 8, _hoisted_7), [
                [_vModelText, _ctx.state.localData[index].answer]
              ])
            ]))
      ]))
    }), 128)),
    (_ctx.pageState.isEditMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _withDirectives(_createElementVNode("div", _hoisted_9, " Each Security Question answer must be at least 3 characters. ", 512), [
            [_vShow, !_ctx.isValid()]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        (_ctx.pageState.isViewMode)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "ms-auto btn btn-primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pageState.changeFormMode(_ctx.FormModeEnum.Edit)))
            }, "Change Security Questions"))
          : _createCommentVNode("", true),
        (_ctx.pageState.isEditMode)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-warning me-auto",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pageState.cancelChanges && _ctx.pageState.cancelChanges(...args)))
            }, "Cancel"))
          : _createCommentVNode("", true),
        (_ctx.pageState.isEditMode)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "btn btn-primary",
              disabled: !_ctx.isValid(),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pageState.applyChanges()))
            }, "Update Security Questions", 8, _hoisted_12))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}