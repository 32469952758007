
import { defineComponent, PropType, computed } from 'vue'
import { PageState, usePageState } from '@/common/pageState'

export default defineComponent({
  props: {
    modelValue: [String, Number],
    items: {
      type: Object as PropType<any[]>,
      required: true
    },
    valueProp: {
      type: String,
      required: false,
      default: 'id' 
    },
    textProp: {
      type: [String, Function],
      required: false,
      default: 'name'
    },
    pageState: {
      type: Object as PropType<PageState>,
      required: false
    },
    undefinedLabel: {
      type: String,
      required: false,
      default: '(Select)'
    },
    undefinedValue: {
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    const ps = props.pageState ?? usePageState()

    const formattedValue = computed(() => {
      if (props.items.find(i => i[props.valueProp]) === undefined) {
        return props.undefinedLabel
      }
      var item = props.items.find(i => i[props.valueProp].toString() === props.modelValue?.toString())
      return item ? (typeof props.textProp === 'string' ? item[props.textProp] : props.textProp(item)) : props.undefinedLabel
    })

    const optionText = (item: any) => {
      if (typeof (props.textProp) === 'string') return item[props.textProp]
      if (typeof (props.textProp) === 'function') return props.textProp(item)
    }

    const emitUpdate = (value: number | string) => {
      let output: number | string = value
      if (typeof (props.modelValue) === 'number') output = Number(value)
      emit('update:modelValue', output)
    }

    return {
      ps,
      formattedValue,
      optionText,
      emitUpdate
    }
  }
})
