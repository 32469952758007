export const graduateTrackingFormStatusClassHelper = {
  badgeClass: (status: string) => {
    status = status ?? 'pending'
    switch (status.toLowerCase()) {
      case 'pending':
        return 'badge-warning'
      case 'queued':
        return 'badge-secondary bg-secondary'
      case 'approved':
        return 'badge-success'
      case 'rejected':
        return 'badge-danger'
    }

    if (status.toLowerCase().includes('pending')) return 'badge-info'

    return 'bg-primary'
  }
}

export const loanVoucherStatusClassHelper = {
  badgeClass: (status: string) => {
    status = status ?? 'pending'
    switch (status.toLowerCase()) {
      case 'queued':
        return 'badge-warning'
      case 'pending':
        return 'badge-info'
      case 'accepted':
        return 'badge-primary'
      case 'approved':
        return 'badge-success'
    }

    if (status.toLowerCase().includes('pending')) return 'badge-info'

    return 'bg-primary'
  }
}

export const loanStatusClassHelper = {
  badgeClass: (status: string) => {
    status = status ?? 'pending'
    switch (status.toLowerCase()) {
      case 'queued':
        return 'badge-warning'
      case 'pending':
        return 'badge-info'
      case 'accepted':
        return 'badge-primary'
      case 'approved':
        return 'badge-success'
    }

    if (status.toLowerCase().includes('pending')) return 'badge-info'

    return 'bg-primary'
  }
}

export const promissoryNoteStatusClassHelper = {
  badgeClass: (status: string) => {
    status = status ?? 'unset'
    switch (status.toLowerCase()) {
      case 'unset':
        return 'badge-light'
      case 'pendingnotificationofeligibility':
        return 'badge-info'
      case 'applicantnotifiedofeligibility':
        return 'badge-secondary'
      case 'applicantinterested':
        return 'badge-primary'
      case 'applicantnotinterested':
        return 'badge-warning'
      case 'offered':
        return 'badge-primary'
      case 'accepted':
        return 'badge-success'
      case 'declined':
        return 'badge-danger'
      case 'processed':
        return 'badge-success'
      case 'forgiven':
        return 'badge-success'
      case 'defaulted':
        return 'badge-danger'
      case 'loanofferrescinded':
        return 'badge-danger'
      case 'canceled':
        return 'badge-danger'
    }

    if (status.toLowerCase().includes('pending')) return 'badge-info'

    return 'bg-primary'
  }
}
