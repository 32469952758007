export const MAX_ROWS_PER_PAGE = 1000000
export const APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY = 'adhe.sams.applicationInfo'
// export const APPLICANT_PORTAL_CACHED_SEARCH_RESULTS_LOCAL_STORAGE_KEY = 'adhe.sams.applicationInfo.searchResultCache'

export const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const SSNRegex = /^\d{3}-?\d{2}-?\d{4}$/

export class PasswordRule {
  description: string = ''
  isValid: (password: string) => boolean = () => false
}
  
class RegexPasswordRule extends PasswordRule {
  regex: RegExp = /.+/
  isValid = (password: string) => (password?.match(this.regex) || []).length > 0

  constructor(description: string, regex: RegExp) {
    super()
    this.regex = regex
    this.description = description
  }
}

export const PasswordComplexityRules = [
  new RegexPasswordRule('Must be 10 characters or more', /.{10,}/),
  new RegexPasswordRule('Must contain at least one number', /\d/),
  new RegexPasswordRule('Must contain at least one lowercase character', /[a-z]/),
  new RegexPasswordRule('Must contain at least one uppercase character', /[A-Z]/),
  new RegexPasswordRule('Must contain at least one special character (@#$%^&*+.!)', /[@#$%^&*+.!]/),
]
