import { Store } from './Store'
import { createApi } from '@/plugin/Api'
import { IUserClaimDto, UserService } from '@/generated/public.api.clients'
import { computed } from 'vue'

export interface IUserStore extends Object {
  claims: IUserClaimDto[] | null
  userName: string | undefined
  userId: string | undefined
  isExternal: boolean
}

export class UserStore extends Store<IUserStore> {
  service = new UserService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

  getClaims = async () => {
    this.state.claims = await this.service.getUserClaims(null)
    this.state.userName = this.state.claims?.find(c => c.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name')?.value
    this.state.userId = this.state.claims?.find(c => c.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier')?.value
    this.state.isExternal = (this.state.claims?.find(c => c.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name')?.value?.indexOf('@') ?? -1) > -1
  }

  getters:any = {
    roles: computed((): string[] => (this.state.claims || []).filter(c => c.type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && typeof c.value !== 'undefined').map(c => c.value!)!),
    isProgramCoordinator: computed((): boolean => this.getters.roles.value.includes('ProgramCoordinator')),
    isReadOnlyFullAccess: computed((): boolean => this.getters.roles.value.includes('ReadOnlyFullAccess')),
    isReadOnlyLimitedAccess: computed((): boolean => this.getters.roles.value.includes('ReadOnlyLimitedAccess')),
    isAdministrator: computed((): boolean => this.getters.roles.value.includes('Administrator')),
    isADHEFinance: computed((): boolean => this.getters.roles.value.includes('ADHEFinance')),
    isEmulatingApplicant: computed((): boolean => (this.state.claims || []).filter(c => c.type === 'https://schemas.rfc.adhe.edu/identity/claims/assumed-applicant-id').length > 0),
    canImpersonate: computed((): boolean => (this.state.claims || []).filter(c => c.type === 'https://schemas.rfc.adhe.edu/identity/claims/impersonation-allowed' && c.value === 'true').length > 0),
    isImpersonating: computed((): boolean => (this.state.claims || []).filter(c => c.type === 'https://schemas.rfc.adhe.edu/identity/claims/impersonated-user-id').length > 0),
    isExternalUser: computed((): boolean =>
      this.getters.roles.value.includes('GTIPProgramAdmin') ||
      this.getters.roles.value.includes('CollegeFAOffice') ||
      this.getters.roles.value.includes('HSCounselor') ||
      (this.getters.roles.value.includes('ReadOnlyFullAccess') && this.state.isExternal)),
    isIHEUser: computed((): boolean => this.getters.roles.value.includes('CollegeFAOffice')),
    isGTIPAdminUser: computed((): boolean => this.getters.roles.value.includes('GTIPProgramAdmin')),
    portals: computed(() => {
      const p:any[] = []

      if (this.getters.roles.value.includes('Administrator')) {
        p.push({ name: 'System Administration', url: '/portal/admin/' })
      }

      if (this.getters.roles.value.includes('ProgramCoordinator') || this.getters.roles.value.includes('ReadOnlyFullAccess')) {
        p.push({ name: 'Financial Aid', url: '/portal/financial-aid/' })
      }

      if (this.getters.roles.value.includes('GTIPProgramAdmin')) {
        p.push({ name: 'National Guard Portal', url: '/portal/financial-aid/ng' })
      }

      if (this.getters.roles.value.includes('CollegeFAOffice')) {
        p.push({ name: 'IHE Portal', url: '/portal/financial-aid/ihe' })
      }

      if (this.getters.roles.value.includes('HSCounselor')) {
        p.push({ name: 'High School Portal', url: '/portal/financial-aid/high-school' })
      }

      if (this.getters.roles.value.includes('ReadOnlyLimitedAccess')) {
        p.push({ name: 'Leadership Portal', url: '/portal/financial-aid/leadership' })
      }

      return p
    })
  }

  protected data (): IUserStore {
    return {
      claims: null,
      userName: undefined,
      userId: undefined,
      isExternal: false
    }
  }
}
