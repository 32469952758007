import { ref, onBeforeMount } from 'vue'
import { APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY } from '@/common/globals'
import { CreateApplicationResponseDto } from '@/generated/public.api.clients'

export function useCachedInProgressApplication() {
  const applicationInProgress = ref(null as CreateApplicationResponseDto | null)

  onBeforeMount(async () => {
    console.log('Attempting to load cached application information...')
    const inProgressApplication = localStorage.getItem(APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY)
    if (inProgressApplication != null) {
      const storedApplication: CreateApplicationResponseDto = JSON.parse(inProgressApplication)
      if (new Date(storedApplication.expirationDate || '2020-01-01') <= new Date()) {
        console.log('Expired!')
        localStorage.removeItem(APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY)
      }
      else {
        applicationInProgress.value = storedApplication
        console.log('Success!')
      }
    }
  })

  return {
    applicationInProgress
  }
}

export function inProgressApplicationToken(): string | undefined {
  const inProgressApplication = localStorage.getItem(APPLICANT_PORTAL_IN_PROGRESS_APPLICATION_LOCAL_STORAGE_KEY)
  if (inProgressApplication != null) {
    const applicationData = JSON.parse(inProgressApplication) as CreateApplicationResponseDto
    return new Date(applicationData.expirationDate || '2020-01-01') > new Date() ? applicationData.accessToken : undefined
  }
}
