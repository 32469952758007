import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        class: "form-select",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedMonth = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthOptions, (o) => {
          return (_openBlock(), _createElementBlock("option", {
            key: o.value,
            value: o.value
          }, _toDisplayString(o.option), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.selectedMonth]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("select", {
        class: "form-select",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedYear = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearOptions, (y) => {
          return (_openBlock(), _createElementBlock("option", {
            key: y,
            value: y
          }, _toDisplayString(y), 9, _hoisted_5))
        }), 128))
      ], 512), [
        [_vModelSelect, _ctx.selectedYear]
      ])
    ])
  ]))
}