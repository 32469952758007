export enum RosterStatusEnum {
  PendingUniversityReview = 12,
  PendingNationalGuardReview = 13,
  PendingADHEAcceptance = 14,
  Accepted = 50,
  Processed = 100
}

export enum ProgramApplicationStatusEnum {
  Draft = 0,
  SubmittedApplication = 1,
  EligibilityPending = 2,
  Eligible = 3,
  Ineligible = 4,
  PendingAcceptance = 5,
  Awarded = 6,
  DeclinedAward = 7,
  Dropped = 8,
  Hold = 9,
  Completed = 10,
  Renewed = 11,
  PendingApproval = 12,
  Accepted = 13,
  Probation = 14,
  AwardNotAccepted = 15,
  PendingAward = 16,
  Deceased = 17,
  Unspecified = 255
}

export enum StudentNotesStoreTypeEnum{
  Applicant = 'Applicant',
  ProgramApplication = 'ProgramApplication'
}

export enum TicketStatusEnum {
  Draft = 1,
  InProgress = 2,
  Completed = 3,
}

export enum MessageStatusEnum
{
    Queued = 0,
    Approved = 1,
    Sent = 2,
    Error = 3,
    Deleted = 4,
    ImmediateSend = 5
}

export enum TicketNoteTypeEnum {
  Public = 1,
  Administrative = 2
}

export enum UserTypeEnum {
  Applicant = 'Applicant',
  Admin = 'Admin',
  IHE = 'IHE',
  Finance = 'Finance',
  HighSchool = 'HighSchool',
  NationalGuard = 'NationalGuard'

}

export enum ScholarshipTypeProgramEnum {
  IncorrectAmount = 2,
  WrongScholarship = 4,
  SummerProbation = 5,
  IncorrectUpload = 6
}

export enum TrafficTypeEnum {
  UserAccounts = 'User Accounts',
  Orphan = 'Orphaned',
  TOP = 'STEP-TOP',
  PrivateSchools = 'Private School',
  OrphanedSystemAccounts = 'Orphaned System Accounts',
}

export enum ArhegInvoiceStatusEnum {
  Pending = 0,
  Submitted = 1,
  Accepted = 2,
  Processed = 3
}

export enum TermEnum {
  Spring = 1,
  Fall = 2,
  Summer = 3
}
