
import { defineComponent, onMounted } from 'vue'
import TrackingList from './components/TrackingList.vue'
import { useDashboardStore } from '../store'

export default defineComponent({
  components: {
    TrackingList
  },
  setup () {
    const store = useDashboardStore()

    onMounted(() => {
      store.getLoanRecords()
    })

    return {
      store
    }
  }
})
