
import { defineComponent, ref, Ref, watch, reactive } from 'vue'
import Modal from '@/components/common/Modal.vue'
import { createApi } from '@/plugin/Api'
import { useToast } from 'vue-toast-notification'
import { SecurityService, UpdatePasswordCommand } from '@/generated/public.api.clients'
import PasswordFeedback from '@/components/common/PasswordFeedback.vue'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  components: {
    Modal,
    PasswordFeedback,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const recaptcha = useReCaptcha()!

    const model = reactive({
      currentPassword: undefined as undefined | string,
      newPassword: undefined as undefined | string,
      confirmPassword: undefined as undefined | string,
      errorResponse: undefined as undefined | string,
      newPasswordIsValid: false
    })

    const showModal: Ref<boolean> = ref(false)
    const toast = useToast()

    watch(() => props.modelValue, () => {
      model.errorResponse = undefined
      showModal.value = props.modelValue
    })

    const service = new SecurityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
    const updatePassword = async () => {
      await recaptcha.recaptchaLoaded()

      service.updatePassword(new UpdatePasswordCommand({ currentPassword: model.currentPassword, password: model.newPassword, recaptcha: await recaptcha.executeRecaptcha('UpdatePassword') }))
        .then(() => {
          showModal.value = false
          toast.success('Password updated successfully', { position: 'top' })
        })
        .catch((err) => {
          model.errorResponse = err.detail
          toast.error('Error updating', { duration: 0, position: 'top' })
        })
    }

    watch(showModal, () => {
      model.currentPassword = undefined
      model.newPassword = undefined
      model.confirmPassword = undefined

      emit('update:modelValue', showModal.value)
    })

    return {
      model,
      showModal,
      updatePassword,
      isValid: () => model.currentPassword && model.newPasswordIsValid
    }
  }
})
