import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row jusitfy-content-md-center mb-3" }
const _hoisted_2 = { class: "card text-center mb-3 mb-xl-0" }
const _hoisted_3 = { class: "card-body p-2" }
const _hoisted_4 = { class: "card-title h5 text-gray-700" }
const _hoisted_5 = { class: "fs-2 mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-3 col-lg-2",
        key: i.status
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.ticketStatusName(i.status)), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass(["fw-bold badge ", _ctx.badgeType(i.status)])
              }, _toDisplayString(i.count), 3)
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}