
/* eslint-disable */
/* vue/no-unused-components */
import { defineComponent } from 'vue'
import { dateTimeFormatters } from '@/common/formatters/dateTime'
import { UserTypeEnum } from '@/common/enumerations'
import LinkColumnRenderer from '@/components/ag-grid/LinkColumnRenderer.vue'

export default defineComponent({
  components: {
    LinkColumnRenderer
  },
  props: {
    ticketList: { type: Object, required: true},
    viewRouterLink: { type: String },
    userType: { type: String }
  },
  setup(props) {

    // User types: Admin/ADHE, Finance
    let columnForAdminsHideStatus = true
    if(props.userType === UserTypeEnum.Admin || props.userType === UserTypeEnum.Finance ) {
        columnForAdminsHideStatus = false
    }

    // User types: High School, National Guard
    let columnForUsersHideStatus = true
    if(props.userType === UserTypeEnum.HighSchool || props.userType === UserTypeEnum.NationalGuard || props.userType === UserTypeEnum.IHE ) {
        columnForUsersHideStatus = false
    }


    const ticketTable = {
      defaultColumnDef: {
        sortable: true,
        filter: true
      },
      columnDefs: [
        {
          field: 'ticketName',
          cellRenderer: 'LinkColumnRenderer',
          cellRendererParams: (row: any) => {
            return {
              linkInfo: {
                linkText: row.data.ticketName,
                linkParams: { name: props.viewRouterLink, params: { id: row.data.id } }
              }
            }
          }
        },
        {
          field: 'statusDisplay',
          headerName: 'Status',
          cellRenderer: (params: any) => {
            let badgeType
            if (params.value === 'Draft') {
              badgeType = 'badge-danger'
            }

            if (params.value === 'InProgress') {
              badgeType = 'badge-warning'
            }

            if (params.value === 'Completed') {
              badgeType = 'badge-success'
            }

            return `<h4 class="ticket-status-badge badge ${badgeType}">${params.value}</h4>`
          }
        },
        { field: 'ticketCategoryName', headerName: 'Category', flex: 1 },
        { field: 'programName', headerName: 'Program', flex: 1 },
        { 
          field: 'created', 
          headerName: 'Created Date', 
          flex: 1, 
          hide: columnForUsersHideStatus,
            cellRenderer: (row: any) => {
            return `${dateTimeFormatters.toShortDateTime(row.data.created)}`
          } 
        },
        {
          field: 'createdByUserName',
          headerName: 'Created',
          autoHeight: true,
          flex: 1,
          hide: columnForAdminsHideStatus,
          cellRenderer: (row: any) => {
            return `<div>${row.data.createdByUserName}</div> <div>${dateTimeFormatters.toShortDateTime(row.data.created)}</div>`
          }
        },
        { field: 'assignedTo', headerName: 'Assigned To', wrapText: true, hide: columnForAdminsHideStatus },
        { field: 'closedByUserName', headerName: 'Closed By', hide: columnForAdminsHideStatus }
      ]
    }

    return {
      ticketTable
    }
  }
})
