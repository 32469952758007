
import { defineComponent, computed } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore'
import { UserTypeEnum } from '@/common/enumerations'

export default defineComponent({
  props: {
    showAllValue: { type: Boolean, default: true },
    userType: { type: String }
  },
  setup(props) {
    const store = useMetadataStore()
    const list = store.metadata!.ticketCategories

    const filteredList = computed(() => {  
      if (props.userType === UserTypeEnum.Applicant) {
        return list?.filter(f => !f.name?.includes('IHE') && !f.name?.includes('National Guard'))
      } else {
        return list
      }
    })

    return {
      store,
      filteredList
    }
  }
})
