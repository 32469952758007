
/* eslint-disable */
/* vue/no-unused-components */
import { defineComponent } from 'vue'
import { dateTimeFormatters } from '@/common/formatters/dateTime'
import { TrafficTypeEnum } from '@/common/enumerations'
import { useUserManagementStore } from '@/modules/portal/admin/views/traffic/store'
import YesNoBadge from '@/components/common/YesNoBadge.vue'

export default defineComponent({
  components: {
    YesNoBadge
  },
  props: {
    trafficType: {
      type: String,
      required: true
    },
    trafficData: {
      type: Object,
      required: true
    },
    searchTerm: {
      type: String,
      required: false
    },
  },
  setup(props) {
    const store = useUserManagementStore()
    const deactivateUser = async (id: string) => {
      if (confirm('Are you sure you want to deactivate this orphaned system account?')) {
        await store.deactivateAspNetUser(id)
        await store.getOrphanedAspNetUsers(props.searchTerm!)
      }
    }
    return {
      dateTimeFormatters,
      TrafficTypeEnum,
      deactivateUser
    }
  },
})
