
import { defineComponent, onBeforeMount, ref, Ref, watch } from 'vue'
import Modal from '@/components/common/Modal.vue'
import { useApplicationSubmissionStore } from '@/modules/portal/applicant/views/application/ProgramApplicationSubmissionStore'
import { useApplicationQuestionStore } from '@/store/ApplicationQuestionStore'
import SamsResponseDisplay from './SamsResponseDisplay.vue'
import LegacyResponseDisplay from './LegacyResponseDisplay.vue'
import { dateTimeFormatters } from '@/common/formatters/dateTime'
export default defineComponent({
  components: { Modal, SamsResponseDisplay, LegacyResponseDisplay },
  props: {
    modelValue: Boolean,
    id: {
      type: Number,
      required: true
    } 
  },
  setup(props, { emit }) {
    const show: Ref<boolean> = ref(false)
    const store = useApplicationSubmissionStore()
    const questionStore = useApplicationQuestionStore()

    onBeforeMount(async () => {
      await questionStore.getQuestions()
    })

    watch(() => props.modelValue, async () => {
      if (props.modelValue) {
        await store.loadApplicationSubmission(props.id, null)
      }
      show.value = props.modelValue
    }, { immediate: true })    

    watch(show, () => {
      emit('update:modelValue', show.value)
    })

    return {
      show,
      store,
      questionStore,
      dateTimeFormatters
    }
  }
})
