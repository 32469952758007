import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ps.isViewMode)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formattedValue), 1))
    : (_ctx.ps.isEditMode)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("select", {
            class: "form-select",
            value: _ctx.modelValue,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitUpdate($event.target.value)))
          }, [
            _createElementVNode("option", { value: _ctx.undefinedValue }, _toDisplayString(_ctx.undefinedLabel), 9, _hoisted_4),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (i) => {
              return (_openBlock(), _createElementBlock("option", {
                key: i[_ctx.valueProp],
                value: i[_ctx.valueProp]
              }, _toDisplayString(_ctx.optionText(i)), 9, _hoisted_5))
            }), 128))
          ], 40, _hoisted_3)
        ]))
      : _createCommentVNode("", true)
}