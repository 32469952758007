
import { defineComponent } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore' 
import Editor from '@tinymce/tinymce-vue'
export default defineComponent({
  components: {
    Editor
  },
  setup () {
    const metadataStore = useMetadataStore()
    return {
      metadataStore
    }
  }
})
