import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-end"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = {
  key: 0,
  class: "text-warning fas fa-exclamation-circle ms-1"
}
const _hoisted_5 = {
  key: 1,
  class: "text-muted small"
}
const _hoisted_6 = {
  key: 1,
  class: "badge bg-secondary"
}
const _hoisted_7 = {
  key: 1,
  class: "badge bg-warning"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.element.isPresent.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.element.hasValue.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.itemById?.name), 1),
              (_ctx.element.isOverridden.value)
                ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                : _createCommentVNode("", true),
              (_ctx.element.dateReceived.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.element.dateReceived.value.toLocaleDateString('en-US')) + " " + _toDisplayString(_ctx.element.dateReceived.value.toLocaleTimeString('en-US')), 1))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, "No Data"))
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_7, "Key not found"))
}