
import { RouterLink } from 'vue-router'
import { computed, defineComponent, inject } from 'vue'
import { UserStore } from '@/store/UserStore'
import { StoreKeys } from '@/store/StoreKeys'

export default defineComponent({
  props: ['params'],
  components: {
    RouterLink
  },
  setup (props) {
    const userStore: UserStore = inject(StoreKeys.UserStoreKey)!
    
    const isIHE = computed(() => userStore.getters.isIHEUser.value)

    return {
      isIHE
    }
  }
})
