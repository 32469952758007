import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!

  return (_openBlock(), _createBlock(_component_editor, {
    id: "internal-body-editor",
    init: {
    height: 450,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help'
  },
    modelValue: _ctx.ticketDescription,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.ticketDescription = $event))
  }, null, 8, ["modelValue"]))
}