import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_grid = _resolveComponent("ag-grid")!

  return (_openBlock(), _createBlock(_component_ag_grid, {
    style: {"height":"550px"},
    class: "ag-theme-alpine",
    defaultColDef: _ctx.ticketTable.defaultColumnDef,
    columnDefs: _ctx.ticketTable.columnDefs,
    rowData: _ctx.ticketList
  }, null, 8, ["defaultColDef", "columnDefs", "rowData"]))
}