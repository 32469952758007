
import { defineComponent, ref } from 'vue'
import { dateTimeFormatters } from '@/common/formatters/dateTime'
import { TicketNoteTypeEnum } from '@/common/enumerations'
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  components: {
    Editor
  },
  props: {
    editorId: {
      type: String,
      required: true
    },
    noteType: {
      type: Number,
      required: true
    },
    noteItems: {
      type: Object,
      required: true
    }
  },
  methods: {
    sendNoteDescription () {
      this.$emit('addNote', this.noteDescription, this.noteType)
      this.noteDescription = ''
    }
  },
  setup(props) {
    const cardHeader = ref('')
    const addBtnName = ref('')
    const editorIdName = ref('')
    const noteDescription = ref('')

    cardHeader.value = 'Comments'
    addBtnName.value = 'Add comment'
    if (props.noteType === TicketNoteTypeEnum.Administrative) {
      cardHeader.value = 'Administrator Notes'
      addBtnName.value = 'Add note'
    }

    return {
      cardHeader,
      addBtnName,
      editorIdName,
      noteDescription,
      dateTimeFormatters
    }
  }
})
