
import { RouterLink } from 'vue-router'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  components: {
    RouterLink
  },
  props: ['params'],
  setup (props) {
    const state = {
      linkText: ref(''),
      linkParams: ref({})
    }

    onMounted(() => {
      state.linkText.value = props.params.linkInfo.linkText || props.params.value
      state.linkParams.value = props.params.linkInfo.linkParams
    })

    return state
  }
})
