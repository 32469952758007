
/* eslint-disable no-unused-expressions */
import { defineComponent, ref, onBeforeMount, Ref, computed } from 'vue'
import { HighSchoolTypeEnum, useMetadataStore } from '@/store/MetadataStore'
export default defineComponent({
  props: {
    modelValue: {
      type: [Number, String],
      required: false
    },
    schoolType: {
      type: Number,
      required: false,
      default: HighSchoolTypeEnum.AllHighSchools
    },
    districtId: {
      type: Number,
      required: false
    },
    allowNullSelection: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showInactiveSchools: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  setup(props) {
    const selectedValue: Ref<number | null> = ref(null)
    const store = useMetadataStore()

    onBeforeMount(() => {
      selectedValue.value = Number(props.modelValue)
    })

    const schoolList = computed(() => {
      return store.getHighSchoolsByType(props.schoolType)
        .filter((s: any) => s.schoolDistrictId === props.districtId || typeof (props.districtId) === 'undefined')
        .filter((s: any) => s.isActive === true || props.showInactiveSchools)
    })

    return {
      selectedValue,
      store,
      schoolList
    }
  }
})
