import { computed } from 'vue'
import { useApplicantApplicationStore } from '@/modules/portal/applicant/store/ApplicantApplicationStore'

export function useProgramApplicationDataElement (dataKey: string) {
  const store = useApplicantApplicationStore()
  const elements = computed(() => store.programApplication?.applicantProgramData!)
  const isPresent = computed(() => Object.keys(elements.value).includes(dataKey))
  const programDataElement = computed(() => elements.value[dataKey])
  const dateReceived = computed(() => programDataElement.value?.dateReceived)
  const finalValue = computed(() => programDataElement.value?.adminOverrideValue || programDataElement.value?.value)
  const hasValue = computed(() => finalValue.value != null && typeof (finalValue.value) !== 'undefined')
  const isOverridden = computed(() => programDataElement.value?.adminOverrideValue != null)

  return {
    isPresent,
    hasValue,
    finalValue,
    isOverridden,
    dateReceived
  }
}
