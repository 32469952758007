import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.internalShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("i", {
            style: {"display":"block"},
            class: _normalizeClass(["fas fa-spinner fa-spin fa-fw mx-auto", _ctx.size])
          }, null, 2)
        ]))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}