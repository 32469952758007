const toYesNo = (input: boolean): string => {
  return input ? 'Yes' : 'No'
}

const toYesNoNoResponse = (input: boolean | null | undefined): string => {
  if (typeof (input) === 'undefined' || input === null) {
    return 'No Response'
  }
  return toYesNo(input)
}

export { toYesNo, toYesNoNoResponse }
