import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ps.isViewMode)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.modelValue), 1))
    : (_ctx.ps.isEditMode)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("input", {
            type: "text",
            class: _normalizeClass(["form-control", { 'text-end' : !_ctx.leftAlign }]),
            value: _ctx.modelValue,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 42, _hoisted_3)
        ]))
      : _createCommentVNode("", true)
}