
import { defineComponent, inject, watchEffect } from 'vue'
import { PasswordComplexityRules, PasswordRule } from '@/common/globals'
import { UserStore } from '@/store/UserStore'
import { StoreKeys } from '@/store/StoreKeys'

function containsThreeMatchingSequence(candidate: string, shouldNotHaveSequence: string[]) {
  for (var i = 0; i < candidate.length - 3; i++) {
    if (shouldNotHaveSequence.map(test => test.toLowerCase()).some(test => test.includes(candidate.substr(i, 3).toLowerCase()))) {
      return true
    }
  }
  return false
}

export default defineComponent({
  props: {
    password: {
      type: String,
      required: true
    },
    confirmPassword: {
      type: String,
      required: true
    },
    cannotMatchSequence: {
      type: Array,
      required: false
    },
  },
  setup(props, { emit }) {
    const matchRule = new PasswordRule()
    matchRule.description = 'Must match confirm password'
    matchRule.isValid = () => (props.password === props.confirmPassword)

    let PasswordRules = [...PasswordComplexityRules, matchRule]

    const userStore: UserStore = inject(StoreKeys.UserStoreKey)!

    let sequences : Array<string> = [...(props.cannotMatchSequence || [])].map(s => (s || '') as string)
    if (userStore) {
      const claims = userStore.getState().claims

      const firstname = (claims || []).find(c => c.type!.includes('givenname'))?.value || ''
      const lastname = (claims || []).find(c => c.type!.includes('surname'))?.value || ''
      const username = (claims || []).find(c => c.type!.includes('name'))?.value || ''
      sequences = [...sequences, firstname, lastname, username]
    }
    const passwordsMatch = new PasswordRule()
    passwordsMatch.description = 'Must match confirm password'
    passwordsMatch.isValid = () => props.password === props.confirmPassword

    PasswordRules = [...PasswordComplexityRules, passwordsMatch]
    if (sequences.length > 0) {
      const noThreeCharacterSequences = new PasswordRule()
      noThreeCharacterSequences.description = 'Must not match a 3 consecutive character sequence of your first name, last name, or email'
      noThreeCharacterSequences.isValid = () => !containsThreeMatchingSequence(props.password, sequences)

      PasswordRules = [...PasswordRules, noThreeCharacterSequences]
    }

    watchEffect(() => {
      const isValid = PasswordRules.every(r => r.isValid(props.password || ''))
      emit('update:modelValue', isValid)
    })

    return {
      PasswordRules,
    }
  }
})
