import axios, { AxiosInstance } from 'axios'
import Vue from 'vue'
// import AuthService from '@/services/AuthService'

export const createApi = (baseUrl: string): AxiosInstance => {
  const myAxios = axios.create({ baseURL: baseUrl, withCredentials: true })

  myAxios.interceptors.request.use(
    config => {
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  myAxios.interceptors.response.use(
    response => response,
    async (error) => {
      // default  error message
      let message = 'Unknown'
      let title = 'Error'

      // determine error message
      if (error === 'Error: Network Error') {
        message = 'Network Error'
      } else {
        const messageObject = error.response.data || error.response.status

        if (Object.prototype.toString.call(messageObject) === '[object Object]') {
          message = `Server error: ${messageObject.title}`
          title = `Error ${messageObject.status}`
        } else if (messageObject === '401') {
          message = 'Server error: Unauthorized'
          title = `Error ${messageObject}`
        } else {
          message = 'Server error'
          title = `Error ${messageObject}`
        }
      }

      return Promise.reject(error.response)
    }
  )

  return myAxios
}
