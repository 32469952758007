import { ComputedRef } from 'vue'

const defaultCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
})

export const centsCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2
})

export function toCurrency (value: number | ComputedRef<number> | undefined, formatter?: Intl.NumberFormat): string | undefined {
  let val = value
  if (typeof (val) !== 'undefined') {
    if (!(value instanceof Number || typeof value === 'number')) {
      val = (value as ComputedRef<number>).value
    }

    return isNaN(val as number) ? '' : (formatter || defaultCurrencyFormatter).format(val as number)
  }
}
