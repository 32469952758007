
import { useApplicationSubmissionStore } from '@/modules/portal/applicant/views/application/ProgramApplicationSubmissionStore'
import { useApplicationQuestionStore } from '@/store/ApplicationQuestionStore'
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const questionStore = useApplicationQuestionStore() 

    const questionText = (questionId: number): string | undefined => {
      const question = questionStore.getQuestionById(questionId)
      
      if (question) {
        return question?.questionText
      } else {
        if (questionId === 5185) return 'Type:' 

        const base = questionStore.getQuestionById(10 * Math.floor(questionId / 10))
        const subQuestionOFfset = questionId % 10

        if (base) {
          switch (subQuestionOFfset) {
            case 1:
              return 'Number of years you held office:'
            case 2:
              return 'State or National Office'
          }
          return base?.metadata?.extendedMetadata?.find(q => q.questionNumber === (subQuestionOFfset - 1))?.questionText
        }
      }
    }

    return {
      store: useApplicationSubmissionStore(),
      questionText
    }
  }
})
