import {
  EmailTemplatesService,
  EmailTemplateTypeEnum,
  IEmailTemplateDto,
  UpdateEmailTemplateCommand,
  AddEmailTemplateCommand
} from '@/generated/financialAid.api.clients'
import { createApi } from '@/plugin/Api'
import { defineStore } from 'pinia'

const service = new EmailTemplatesService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))
export const useEmailTemplatesStore = defineStore('email-templates-store', {
  state: () => {
    return {
      currentTemplate: null as IEmailTemplateDto | null,
      templates: null as IEmailTemplateDto[] | null,
      systemTemplates: null as IEmailTemplateDto[] | null,
      holdRequestTemplates: null as IEmailTemplateDto[] | null,
      studentListTemplates: null as IEmailTemplateDto[] | null,
    }
  },
  actions: {
    async getTemplates (): Promise<void> {
      this.templates = await service.get(null)
      this.systemTemplates = this.templates.filter(t => t.type === EmailTemplateTypeEnum.System)
      this.studentListTemplates = this.templates.filter(t => t.type === EmailTemplateTypeEnum.AdHoc)
    },
    async getHoldRequestTemplates () : Promise<void> {
      this.holdRequestTemplates = (await service.getHoldRequestTemplates()).filter(t => t.type === EmailTemplateTypeEnum.System)
    },
    async updateTemplate(args:{subject:string, internalBody:string, emailBody:string}): Promise<void> {
      this.currentTemplate = await service.updateEmailTemplate(new UpdateEmailTemplateCommand({
        id: this.currentTemplate?.id!,
        subject: args.subject,
        internalBody: args.internalBody,
        emailBody: args.emailBody
      }), this.currentTemplate?.id.toString()!)
        .then(result => {
          const index = this.templates!.findIndex(o => { return o.id === result.id })
          this.templates?.splice(index, 1, result)
          return result
        })
        .catch(error => {
          console.log(error)
          throw error
        })
    },
    async addTemplate(args:{subject:string, internalBody:string, emailBody:string}): Promise<void> {
      await service.addEmailTemplate(new AddEmailTemplateCommand({
        description: args.subject,
        subject: args.subject,
        emailBody: args.emailBody,
        internalBody: args.internalBody,
        name: args.subject,
        active: true,
        type: EmailTemplateTypeEnum.AdHoc
      }))
        .then(result => {
          this.templates?.push(result)
          return result
        })
        .catch(error => {
          console.log(error)
          throw error
        })
    },
    async deleteTemplate (id: number): Promise<void> {
      await service.deleteEmailTemplate(id)
        .then(() => {
          const index = this.templates!.findIndex(o => { return o.id === id })
          this.templates?.splice(index, 1)
        })
        .catch(error => {
          console.log(error)
          throw error
        })
    },
    setCurrentTemplate (id: number): void {
      console.log(`id :${id}`)
      this.currentTemplate = this.templates?.find(t => t.id === id) || null
    }
  }
})
