
import { defineComponent, PropType, computed } from 'vue'
import { useProgramApplicationDataElement } from './programApplicationDataElement'
import { ILookupListItem } from '@/generated/public.api.clients'

export default defineComponent({
  components: {
  },
  props: {
    dataKey: {
      type: String,
      required: true
    },
    listItems: {
      type: Object as PropType<ILookupListItem[]>,
      required: true
    },
  },
  setup(props) {
    const element = useProgramApplicationDataElement(props.dataKey)
    const itemById = computed((): ILookupListItem | undefined => {
      return props.listItems.find(i => i.id === Number(element.finalValue.value))
    })

    return {
      itemById,
      element
    }
  }
})
