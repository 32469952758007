
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String,
      default: 'Loading, please wait...'
    }
  },
  setup () {
    return {
    }
  }
})
