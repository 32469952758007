
import { useApplicationSubmissionStore } from '@/modules/portal/applicant/views/application/ProgramApplicationSubmissionStore'
import { useApplicationQuestionStore } from '@/store/ApplicationQuestionStore'
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const leadershipModel = (unformatted: string | undefined): { grades: number[], officerYears: number | undefined, leaderType: { text: string }[], officer: { text: string }[], additionalText: string | undefined } => JSON.parse(unformatted || '')

    return {
      store: useApplicationSubmissionStore(),
      questionStore: useApplicationQuestionStore(),
      leadershipModel
    }
  }
})
