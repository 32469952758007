
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    elementId: {
      type: String,
      default: 'actionButton'
    }
  },
  setup () {
  }
})
