
import { FormModeEnum, PageState } from '@/common/pageState'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    canEdit: {
      type: [Boolean],
      default: true
    },
    canSave: {
      type: [Boolean],
      default: true
    },
    pageState: {
      type: Object as PropType<PageState>,
      required: true
    },
    enforceDirty: {
      type: [Boolean],
      default: true
    },
  },
  components: {
    
  },
  setup(props) {
    const onEdit = () => {
      props.pageState.changeFormMode(FormModeEnum.Edit)
    }

    const onSave = () => {
      props.pageState.applyChanges()
    }

    const onDiscard = () => {
      props.pageState.cancelChanges()
    }

    const disabled = () => {
      if (!props.canSave) return true

      if (props.enforceDirty && !props.pageState.isDirty) {
        return true
      }

      return false
    }

    return {
      onEdit,
      onSave,
      onDiscard,
      disabled
    }
  }
})
