
import { defineComponent, ref } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore'
import { ScholarshipTypeProgramEnum } from '@/common/enumerations'

export default defineComponent({
  props: {
    showAllValue: { type: Boolean, default: true },
    isCreatedMode: { type: Boolean, default: false },
    selectedTicketCategory: { type: Number, default: 0 } // only call this prop on create ticket page. it disables the dropdown and resets the selected value based on the selected ticket category.
  },
  setup(props) {
    const metadataStore = useMetadataStore()
    const selectedProgram = ref(0)
    const ids = Object.values(ScholarshipTypeProgramEnum)

    // disableControl only applies on create ticket page
    const disableControl = (ticketCategoryId: number) => {
      if (props.isCreatedMode) {
        if (!ids.includes(ticketCategoryId)) {
          selectedProgram.value = 0
          return true
        }
        return false
      }
    }

    return {
      metadataStore,
      selectedProgram,
      disableControl
    }
  }
})
