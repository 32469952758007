import { IApplicationQuestionDto, AnswerOption } from '@/generated/public.api.clients'
import { MetadataStore, useMetadataStore } from '@/store/MetadataStore'

export interface ICondition {
  questionId: string,
  value?: string,
  min?: string,
  max?: string,
  asserts?: Record<string, string | undefined>
}

export interface IConditionGroup {
  condition: ICondition[]
  _text?: string
  asserts?: Record<string, string | undefined>
}

export interface IConditionContainer {
  or?: IConditionGroup[] | undefined
  condition?: ICondition[] | undefined
}

export interface IRoutingDirective {
  or?: IConditionGroup[]
  condition?: ICondition[]
  nextpage?: string
  nexturl?: string
}

export interface IQuestion extends IConditionContainer {
  id: string
}

export interface IPage {
  question: IQuestion[]
  section?: {
    question: IQuestion[],
    title: string
  }[],
  routing: IRoutingDirective[]
  requirement?: IConditionContainer,
  title?: string,
  id: string
}

export class SearchAndApplyUtility {
  _metadataStore: MetadataStore

  constructor () {
    this._metadataStore = useMetadataStore()
  }

  public getAnswerText = (answerValue: string | number, question: IApplicationQuestionDto): string => {
    if (question.metadata?.optionSource === 'States') {
      return this._metadataStore.metadata?.states?.find(s => s.abbreviation === answerValue)?.name || answerValue.toString()
    } else if (question.metadata?.optionSource === 'YesNo') {
      return answerValue.toString() === '1' ? 'Yes' : 'No'
    } else if (['ARPublicHighSchools', 'ARHighSchools', 'ARPrivateHighSchools'].includes(question.metadata?.optionSource || '')) {
      return this._metadataStore.highSchoolsDictionary.getNameById(Number(answerValue)) || answerValue.toString()
    } else if (question.metadata?.answers) {
      const selectedAnswer = question.metadata?.answers!.find((a: AnswerOption) => a.value.toString() === answerValue.toString())
      return selectedAnswer?.text || answerValue.toString()
    }

    return answerValue.toString()
  }
}

export const HighSchoolGrades: { value: number, text: string }[] = [9, 10, 11, 12].map((m) => { return { value: m, text: `${m}th` } })
export const NumberOfYears: number[] = [0, 1, 2, 3, 4]
export const StateOrDistrict: { value: number, text: string }[] = [{ value: 1, text: 'State' }, { value: 2, text: 'District' }]
export const StateOrNational: { value: number, text: string }[] = [{ value: 1, text: 'State' }, { value: 2, text: 'National' }]
