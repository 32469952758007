import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  value: ""
}
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    class: "form-select",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedValue = $event)),
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.selectedValue || null)))
  }, [
    (_ctx.allowNullSelection)
      ? (_openBlock(), _createElementBlock("option", _hoisted_1, "N/A"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schoolList, (i) => {
      return (_openBlock(), _createElementBlock("option", {
        key: i.id,
        value: i.id
      }, _toDisplayString(i.name), 9, _hoisted_2))
    }), 128))
  ], 544)), [
    [_vModelSelect, _ctx.selectedValue]
  ])
}