<template>
  <footer>
    <div class="container">
      <div class="footer-wrapper">
        <section id="FooterContact">
          <h1>Contact Us</h1>
          <div class="address">
            Arkansas Division of Higher Education
            <br />
            101 E. Capitol Ave., Suite 300
            <br />
            Little Rock, AR 72201
          </div>
          <div class="d-flex justify-content-center">
            <span class="p-1"><a href="mailto:finaid@adhe.edu">Email: Financial Aid Eligibility</a></span>
            <span class="p-1">|</span>
            <span class="p-1"><a href="mailto:SAMS.PortalSupport@ade.arkansas.gov">Email: Technical Support</a></span>
          </div>
          <div>
            <a href="tel:5013712000">501.371.2000</a>
          </div>
        </section>
        <section id="FooterSocialMedia">
          <h1>Follow Us</h1>
          <p>
            <a href="https://www.facebook.com/ArkDeptHigherEd" target="_blank" aria-label="ADHE Facebook link"><i class="fab fa-facebook-square"></i></a>
            <a href="https://twitter.com/arkhighered" target="_blank" aria-label="ADHE Twitter link"><i class="fab fa-twitter-square"></i></a>
          </p>
        </section>
      </div>
      <p id="FooterCopyright">Copyright &copy; 2022 Arkansas Division of Higher Education, Arkansas All Rights Reserved.</p>
    </div>
  </footer>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
  },
})
</script>
