import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: _ctx.topRoute }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: require('@/assets/logo-SAMS.png'),
        class: _normalizeClass(_ctx.className),
        alt: "SAMS logo"
      }, null, 10, _hoisted_1)
    ]),
    _: 1
  }, 8, ["to"]))
}