
import { defineComponent, PropType, ref } from 'vue'
import { ILookupListItem } from '@/generated/public.api.clients'
export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      required: false
    },
    modelItems: {
      type: Array as PropType<ILookupListItem[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const selectedValue = ref(props.modelValue)

    const onSelected = () => {
      emit('update:modelValue', selectedValue.value === undefined ? null : selectedValue.value)
      emit('item-selected', props.modelItems.find(i => i.id === selectedValue.value))
    }

    return {
      selectedValue,
      props,
      onSelected
    }
  }
})
