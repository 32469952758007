
import { defineComponent, computed, watch, reactive, Ref, ref } from 'vue'

export default defineComponent({
  props: {
    pendingLabel: {
      type: String,
    },
    clickHandler: {
      type: Function,
      required: true
    },
    disabled: {
      type: [Boolean, Function],
      default: () => false
    },
    continueLoadingOnSuccess: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const promise: Ref<Promise<any> | undefined> = ref(undefined)

    // console.log(props)
    const state = reactive({
      status: 'dormant'
    })

    const buttonLabel = computed((): string | undefined => {
      return state.status === 'pending' ? props.pendingLabel || 'Please wait...' : undefined
    })

    const isDisabled = computed(() => {
      if (state.status === 'dormant') {
        if (typeof (props.disabled) === 'boolean') return props.disabled
        if (typeof (props.disabled) === 'function') return props.disabled()
      }

      return true
    })

    watch(() => promise.value, (newVal) => {
      // console.log('promise changed....', newVal)

      if (newVal) {
        state.status = 'pending'
        newVal.then(() => {
          if (!props.continueLoadingOnSuccess) {
            state.status = 'dormant'
          }
        }).catch((_err) => {
          state.status = 'error'
        })
      }
    }, { immediate: true, deep: true })

    const onClick = (e: any) => {
      e.preventDefault()
      promise.value = props.clickHandler()
    }

    return {
      isDisabled,
      buttonLabel,
      onClick,
      state
    }
  }
})
