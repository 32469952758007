
import { defineComponent, ref, onBeforeMount, Ref } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore'
export default defineComponent({
  props: {
    modelValue: {
      type: [Number, String],
      required: false
    },
    allowNullSelection: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  setup (props) {
    const selectedValue: Ref<number | null> = ref(null)
    const store = useMetadataStore()

    onBeforeMount(() => {
      selectedValue.value = Number(props.modelValue)
    })
    return {
      selectedValue,
      store
    }
  }
})
