
import { defineComponent } from 'vue'
import LogoSams from '@/components/common/layout/LogoSAMS.vue'
import LogoAdhe from '@/components/common/layout/LogoADHEWithLabel.vue'
import LogoAde from '@/components/common/layout/LogoADE.vue'

export default defineComponent({
  components: {
    LogoSams,
    LogoAdhe,
    LogoAde
  },
  props: {
    showSAMSLogo: { type: Boolean, default: true },
    showADHELogo: { type: Boolean, default: true },
    showADELogo: { type: Boolean, default: true },
    topRoute: { type: Object, required: true }
  },
  setup() {}
})
