
import { defineComponent, Ref, ref, watch } from 'vue'
import Modal from '@/components/common/Modal.vue'
import { useToast } from 'vue-toast-notification'
import { AccountManagementService, AdminChangeEmailAddressCommand } from '@/generated/financialAid.api.clients'
import { createApi } from '@/plugin/Api'

export default defineComponent({
  props: {
    modelValue: Boolean,
    applicantId: Number, 
    userId: String,
    currentEmail: String
  },
  components: {
    Modal
  },
  setup(props, { emit }) {
    const show: Ref<boolean> = ref(false)
    const errorResponse = ref('')
    const email = ref('')
    const confirmEmail = ref('')
    const toast = useToast()

    const service = new AccountManagementService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))

    const submit = async () => {
      if (email.value === confirmEmail.value) {
        await service.adminChangeEmailAddress(new AdminChangeEmailAddressCommand({ applicantId: props.applicantId || undefined, newEmail: email.value, userId: props.userId || undefined }))
          .then(() => {
            show.value = false
            toast.success('Change Email Notification Sent.', { position: 'top' })
          })
      }
      else {
        toast.error('Emails do not match.', { position: 'top' })
      }
    }

    watch(() => props.modelValue, () => {
      show.value = props.modelValue
    }, { immediate: true })

    watch(show, () => {
      emit('update:modelValue', show.value)
    })

    return {
      show,
      errorResponse,
      email,
      confirmEmail,
      submit
    }
  }
})

