import { LoanTrackingStatusEnum } from '@/generated/applicant.api.clients'

export const toFriendlyStatus = (status: LoanTrackingStatusEnum): string => {
  switch (status) {
    case (LoanTrackingStatusEnum.Queued): return 'Queued'
    case (LoanTrackingStatusEnum.ApplicantNotified): return 'Information Due'
    default: return LoanTrackingStatusEnum[status]
  }
}

export const loanStatusClassHelper = {
  badgeClass: (status: LoanTrackingStatusEnum): string => {
    switch (status)
    {
      case LoanTrackingStatusEnum.Queued:
      case LoanTrackingStatusEnum.ApplicantNotified:
        return 'badge-warning'
      case LoanTrackingStatusEnum.Submitted:
        return 'bg-primary'
      case LoanTrackingStatusEnum.Rejected:
        return 'badge-danger'
      case LoanTrackingStatusEnum.Approved:
        return 'badge-success'
      default: 
        return 'bg-primary'
    }
  }
}
