
import { defineComponent, onMounted, ref } from 'vue'
import { useUserManagementStore } from './store'
import { TrafficTypeEnum } from '@/common/enumerations'
import InBoundTrafficTable from '@/components/common/InBoundTrafficTable.vue'
import LoadingPanel from '@/components/common/LoadingPanel.vue'

export default defineComponent({
  components: {
    InBoundTrafficTable,
    LoadingPanel
  },
  setup() {
    const store = useUserManagementStore()
    const searchKey = ref('')
    const loading = ref(false)
    const trafficType = TrafficTypeEnum.OrphanedSystemAccounts

    const actions = {
      searchUsers: async () => {
        loading.value = true
        await store.getOrphanedAspNetUsers(searchKey.value)
        loading.value = false
      }
    }

    onMounted(async () => {
      loading.value = true
      await store.getOrphanedAspNetUsers('')
      loading.value = false
    })
    return {
      store,
      searchKey,
      actions,
      trafficType,
      loading
    }
  }
})
