import { reactive, readonly } from 'vue'

// pattern based on https://medium.com/@mario.brendel1990/vue-3-the-new-store-a7569d4a546f
export abstract class Store<T extends Object> {
    protected state: T

    constructor () {
      const data = this.data()
      this.setup(data)
      this.state = reactive(data) as T
    }

    protected abstract data (): T

    protected setup (data: T): void {}

    public getState (): T {
      console.log('returning readonly state')
      return readonly(this.state) as T
    }
}
