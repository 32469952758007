import { IProgramFamily } from '@/generated/financialAid.api.clients'
import {
  ICollegeListItemDto,
  IHighSchoolDto,
  ILookupListItem,
  IMetadataDto,
  IReasonDescriptionsLookupListItem,
  ISchoolDistrictDto,
  MetadataService,
  IStateLookupListItem,
  IProgramLookupListItem,
  LookupListItemOfGuid,
  IShortageMetadataDto,
  IProgramFamilyDto
} from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import sortBy from 'lodash.sortby'
import { defineStore } from 'pinia'

const service = new MetadataService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

export enum HighSchoolTypeEnum {
  PublicHighSchool = 1,
  PrivateHighSchool = 2,
  AllHighSchools = 3
}

export type DictionaryLookupType = IHighSchoolDto | ICollegeListItemDto | ISchoolDistrictDto | ILookupListItem | IReasonDescriptionsLookupListItem | IStateLookupListItem

class Dictionary {
  map: Map<Number, DictionaryLookupType> = new Map()
  constructor (items: DictionaryLookupType[]) {
    items.forEach((i) => this.map.set(i.id, i))
  }

  itemById = (id: Number) => {
    return this.map.get(id)
  }

  getNameById = (id: Number, fallbackName?: string | undefined) => {
    return this.itemById(id)?.name || fallbackName
  }
}

export const useMetadataStore = defineStore('metadata-store', {
  state: () => {
    return {
      colleges: [] as ICollegeListItemDto[],
      highSchools: null as IHighSchoolDto[] | null,
      schoolDistricts: null as ISchoolDistrictDto[] | null,
      programFamilies: null as IProgramFamilyDto[] | null,
      metadata: {
        rosterStatuses: [] as ILookupListItem[],
        terms: [] as ILookupListItem[],
        states: [] as IStateLookupListItem[],
        counties: [] as ILookupListItem[],
        genders: [] as ILookupListItem[],
        races: [] as ILookupListItem[],
        collegeTypes: [] as ILookupListItem[],
        rosterTypes: [] as ILookupListItem[],
        programs: [] as IProgramLookupListItem[],
        documentTypes: [] as ILookupListItem[],
        programApplicationStatuses: [] as ILookupListItem[],
        arhegFields: [] as ILookupListItem[],
        reasonDescriptions: [] as IReasonDescriptionsLookupListItem[],
        studentTransactionTypes: [] as ILookupListItem[],
        leoRelationships: [] as ILookupListItem[],
        leoCategories: [] as ILookupListItem[],
        mdsCategories: [] as ILookupListItem[],
        mdsRelationships: [] as ILookupListItem[],
        applicationRoles: [] as LookupListItemOfGuid[],
        ticketCategories: [] as ILookupListItem[],
      } as IMetadataDto | null,
      metadataLoaded: false,
      shortageMetadata: null as IShortageMetadataDto | null
    }
  },
  actions: {
    async initialize () {
      this.metadataLoaded = false

      return Promise.all([this.getColleges(),
        this.getHighSchools(),
        this.getSchoolDistricts(),
        this.getProgramFamilies(),
        this.getMetadata()])
        .then(async () => {
          this.metadataLoaded = true
          console.log('Metadata loaded')
        })
    },
    async getHighSchools () {
      this.highSchools = sortBy(await service.getHighSchools(), 'name')
    },
    async getProgramFamilies () {
      this.programFamilies = await service.getProgramFamiliesWithAbbreviations()
    },
    async getSchoolDistricts () {
      this.schoolDistricts = sortBy(await service.getSchoolDistricts(), 'name')
    },
    async getColleges () {
      this.colleges = sortBy(await service.getColleges(), 'name')
    },
    async getMetadata () {
      this.metadata = await service.getMetadata()
    },
    async getShortageMetadata () {
      this.shortageMetadata = await service.getShortageMetadata()
    },
    async getInformationSheetText(id:number): Promise<string> {
      return service.getInformationSheetTextByIdRequest(id, undefined)
    }
  },
  getters: {
    activeColleges: (state): ICollegeListItemDto[] => state.colleges.filter(c => c.isActive),
    arhegColleges: (state): ICollegeListItemDto[] => state.colleges.filter(c => c.isArheg),
    highSchoolsDictionary: (state): Dictionary => new Dictionary(state.highSchools || []),
    collegesDictionary: (state): Dictionary => new Dictionary(state.colleges || []),
    schoolDistrictsDictionary: (state): Dictionary => new Dictionary(state.schoolDistricts || []),
    statesDictionary: (state): Dictionary => new Dictionary(state?.metadata?.states || []),
    rosterStatusesDictionary: (state): Dictionary => new Dictionary(state.metadata?.rosterStatuses || []),
    termsDictionary: (state): Dictionary => new Dictionary(state.metadata?.terms || []),
    arhegPrograms: (state): IProgramLookupListItem[] => state.metadata?.programs?.filter(p => /ARHEG/.test(p.abbreviation ?? '')) || [],
    programApplicationStatusesDictionary: (state): Dictionary => new Dictionary(state.metadata?.programApplicationStatuses || []),
    reasonDescriptionsDictionary: (state): Dictionary => new Dictionary(state.metadata?.reasonDescriptions || []),
    getHighSchoolsByType: (state): Function => {
      return (type: HighSchoolTypeEnum): IHighSchoolDto[] | null | undefined => {
        if (type === HighSchoolTypeEnum.PrivateHighSchool) return state.highSchools?.filter(s => s.isPrivate)
        if (type === HighSchoolTypeEnum.PublicHighSchool) return state.highSchools?.filter(s => !s.isPrivate)
        return state.highSchools
      }
    },
    geographicShortageDistricts: (state) => state.schoolDistricts?.filter(sd => sd.geoShortageDistrict),
    additionalEducationCategories: (): { id: number, name: string }[] => {
      return [
        { id: 0, name: 'Science' },
        { id: 1, name: 'Technology' },
        { id: 2, name: 'Engineering' },
        { id: 3, name: 'Mathematics' },
        { id: 4, name: 'Computer Science' },
        { id: 5, name: 'Literacy / Reading' },
        { id: 6, name: 'Prekindergarten Education' },
        { id: 7, name: 'Special Education' },
        { id: 8, name: 'Other' }
      ]
    },
    freshmanSemestersWaivedOptions: () : { id: number, name: string }[] => {
      return [
        { id: 0, name: 'No' },
        { id: 1, name: 'Waive Freshman Award' },
        { id: 2, name: 'Waive Sophomore Award' }
      ]
    },
    freshmanWaiverTranscriptSourceOptions: () : { id: number, name: string }[] => {
      return [
        { id: 0, name: 'Electronic' },
        { id: 1, name: 'College Mailed' },
        { id: 2, name: 'Applicant Mailed' }
      ]
    }
  }
})

export type MetadataStore = Omit<
    ReturnType<typeof useMetadataStore>,
    keyof ReturnType<typeof defineStore>
>
