import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  href: "https://ade.arkansas.gov/",
  target: "_blank"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/logo-ADE.png'),
      class: _normalizeClass(_ctx.className),
      alt: "ADE Logo"
    }, null, 10, _hoisted_2)
  ]))
}