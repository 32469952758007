

import { onMounted, defineComponent, ref } from 'vue'
export default defineComponent({
  props: ['params'],
  setup (props) {
    const state = {
      value: ref(false),
      trueText: ref('Yes'),
      trueIconClass: ref('fas fa-check-circle'),
      trueBadgeClass: ref('badge-success'),
      falseIconClass: ref('fas fa-times-circle'),
      falseText: ref('No'),
      falseBadgeClass: ref('badge-danger')
    }

    onMounted(() => {
      state.value.value = props.params.value
      state.trueText.value = props.params.trueText || state.trueText.value
      state.trueBadgeClass.value = props.params.trueBadgeClass || state.trueBadgeClass.value
      state.trueIconClass.value = props.params.trueIconClass || state.trueIconClass.value
      state.falseText.value = props.params.falseText || state.falseText.value
      state.falseBadgeClass.value = props.params.falseBadgeClass || state.falseBadgeClass.value
      state.falseIconClass.value = props.params.falseIconClass || state.falseIconClass.value
    })

    return state
  }
})
