import { IApplicationQuestionDto, ApplicationQuestionsService } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { defineStore } from 'pinia'

const service = new ApplicationQuestionsService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

export const useApplicationQuestionStore = defineStore('application-question-store', {
  state: () => {
    return {
      yearOptions: [] as number[],
      applicationYear: 0,
      applicationQuestions: [] as IApplicationQuestionDto[]
    }
  },
  actions: {
    async getQuestions () : Promise<void> {
      console.log('loading questions')
      const results = await service.getApplicationQuestions(null, undefined)
      this.applicationYear = results.applicationYear

      for (let i = this.applicationYear + 4; i > this.applicationYear - 90; i--) {
        this.yearOptions.push(i)
      }

      const questions = results.questions!
      for (const q of questions) {
        if (q.questionText?.match(/{current_year}/)) q.questionText = q.questionText?.replace(/{current_year}/, this.applicationYear.toString())
        if (q.metadata?.answers) {
          for (const a of q.metadata.answers.filter(t => t.text?.match(/{current_year}/))) {
            a.text = a.text?.replace(/{current_year}/, this.applicationYear.toString())
          }
        }
      }
      this.applicationQuestions = questions
      console.log('questions loaded')
    }
  },
  getters: {
    getQuestionById: (state) => (id: number): IApplicationQuestionDto => {
      return state.applicationQuestions.find(q => q.id === id)!
    },
    getInputTypeById: (state) => (id: number): string => {
      const actScoreQuestionIds = [1065, 1066, 1067, 1068, 1069, 1070]
      const numberOfDependentsQuestionId = [1040]

      if ([...actScoreQuestionIds, ...numberOfDependentsQuestionId].includes(id)) {
        return 'number'
      }

      return 'text'
    }
  }
})
