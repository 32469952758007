<template>
  <span v-if="params.data.applicantId">
    <router-link :to="{name: 'FA/Applicant', params: { id: params.data.applicantId }}">{{ name }}</router-link>
  </span>
</template>

<script lang="js">
import { RouterLink } from 'vue-router'
export default {
  components: {
    RouterLink
  },
  data: function () {
    return {}
  },
  computed: {
    name () {
      return `${this.params.data.lastName}, ${this.params.data.firstName}`
    }
  }
}
</script>
