import { toCurrency } from '@/common/formatters/numeric'
import { toYesNo, toYesNoNoResponse } from '@/common/formatters/boolean'
import { dateTimeFormatters } from '@/common/formatters/dateTime'

export function dateRenderer (params: any): string | undefined {
  if (params === null || params.value === null || typeof (params.value) === 'undefined') return undefined
  return new Date(params.value).toLocaleDateString('en-US')
}

export function nullableDateRenderer (params: any, ifNull: string): string {
  return params.value ? new Date(params.value).toLocaleDateString('en-US') : ifNull
}

export function dateTimeRenderer (params: any): string {
  return dateTimeFormatters.toShortDateTime(new Date(params.value))
}

export function currencyRenderer (params: any): string | undefined {
  if (params === null || params.value === null || typeof (params.value) === 'undefined') return undefined
  if (!params.decimals) return toCurrency(params.value)
  else return toCurrency(params.value, new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: params.decimals }))
}

export function yesNoRenderer (params: any): string {
  return toYesNo(params.value)
}

export function yesNoNoResponseRenderer (params: any): string {
  return toYesNoNoResponse(params.value)
}

export function boolToYesNoGetter (params: any): string {
  switch (params.data[params.colDef.field]) {
    case false: return 'No'
    case true: return 'Yes'
    default: return 'No Response'
  }
}

export function yesNoToBoolSetter (params: any): Boolean {
  switch (params.newValue) {
    case 'No': params.data[params.colDef.field] = false
      break
    case 'Yes': params.data[params.colDef.field] = true
      break
    default: params.data[params.colDef.field] = null
      break
  }

  return true
}

export function intToYesNoWaiveGetter (params: any): string {
  switch (params.data[params.colDef.field]) {
    case 0: return 'No'
    case 1: return 'Yes'
    case 2: return 'Waive'
    default: return 'No Response'
  }
}

export function yesNoWaiveToIntSetter (params: any): Boolean {
  switch (params.newValue) {
    case 'No': params.data[params.colDef.field] = 0
      break
    case 'Yes': params.data[params.colDef.field] = 1
      break
    case 'Waive': params.data[params.colDef.field] = 2
      break
    default: params.data[params.colDef.field] = null
      break
  }

  return true
}
