
/* eslint-disable no-unused-expressions */
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore'
import { ICollegeListItemDto } from '@/generated/public.api.clients'

export default defineComponent({
  props: {
    modelValue: Number,
    colleges: {
      type: Object as PropType<ICollegeListItemDto[] | null>,
      required: false,
      default: () => null
    },
    isArheg: {
      type: Boolean,
      required: false,
      default: false
    },
    showNull: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const selectedValue = ref(props.modelValue)
    const metadataStore = useMetadataStore()

    const collegeList = computed(() => {
      const list = props.colleges ?? metadataStore.colleges
      if (props.isArheg) {
        return list.filter(college => college.isArheg === props.isArheg)
      }
      return list
    })

    watch(() => props.modelValue, () => {
      selectedValue.value = props.modelValue
    })

    return {
      selectedValue,
      metadataStore,
      collegeList
    }
  }
})
