export function formatDate (date: Date): string {
  return date.toLocaleDateString('en-US')
}

const longDateFormatter = new Intl.DateTimeFormat('en-US')
const shortDateFormatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
const shortTimeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' })

const isValidDate = (date: Date | undefined): boolean => (date instanceof Date && !isNaN(date.getTime()))
export const jsonStringToDate = (jsonDate: string): Date => new Date(jsonDate)

export const dateTimeFormatters = {
  toShortDate: (date:Date | undefined): string => {
    return isValidDate(date) ? shortDateFormatter.format(date) : ''
  },
  toShortTime: (date:Date | undefined): string => {
    return isValidDate(date) ? shortTimeFormatter.format(date) : ''
  },
  toDateTime: (date:Date | undefined): string => {
    return isValidDate(date) ? longDateFormatter.format(date) + ' ' + shortTimeFormatter.format(date) : ''
  },
  toShortDateTime: (date:Date | undefined): string => {
    return isValidDate(date) ? shortDateFormatter.format(date) + ' ' + shortTimeFormatter.format(date) : ''
  }
}
