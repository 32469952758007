
import { defineComponent, PropType } from 'vue'
import { PageState, usePageState } from '@/common/pageState'
import { dateTimeFormatters } from '@/common/formatters/dateTime'

export default defineComponent({
  props: {
    modelValue: [String, Number, Date],
    pageState: {
      type: Object as PropType<PageState>,
      required: false
    }
  },
  setup (props) {
    const ps = props.pageState ?? usePageState()

    const formattedValue = () => {
      console.log('Type: ', typeof (props.modelValue))
      if (props.modelValue as any instanceof Date) {
        return dateTimeFormatters.toShortDate(props.modelValue as unknown as Date)
      }

      return props.modelValue
    }

    return {
      formattedValue,
      ps
    }
  }
})
