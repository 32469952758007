
/* eslint-disable no-unused-expressions */
import { defineComponent, ref } from 'vue'
import { useMetadataStore } from '@/store/MetadataStore'
export default defineComponent({
  props: {
    modelValue: Number
  },
  setup (props) {
    const selectedValue = ref(props.modelValue)
    const store = useMetadataStore()

    return {
      selectedValue,
      store
    }
  }
})
