
import { defineComponent, watch, Ref, ref, PropType } from 'vue'
export default defineComponent({
  props: {
    size: {
      type: String,
      required: false,
      default: 'fa-3x'
    },
    loading: {
      type: [Boolean, Promise] as PropType<Boolean | Promise<any>>,
      required: true
    }
  },
  setup (props) {
    const internalShow: Ref<Boolean> = ref(true)

    watch(() => props.loading, (newValue) => {
      if (typeof props.loading === 'undefined') return

      if (typeof props.loading === 'boolean') {
        internalShow.value = Boolean(newValue)
      } else if ((props.loading as unknown as Promise<any>).then) {
        internalShow.value = true;
        
        (props.loading as unknown as Promise<any>).then(() => {
          internalShow.value = false
        })
      }
    }, { immediate: true })
    return {
      internalShow
    }
  }
})
