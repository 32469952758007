
import { defineComponent, computed } from 'vue'
import { classHelper } from '@/common/programApplication'
export default defineComponent({
  props: {
    status: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const statusClass = computed(() => {
      return classHelper.badgeClass(props.status)
    })

    return {
      statusClass
    }
  }
})
