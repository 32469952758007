
import { defineComponent, PropType } from 'vue'
import { PageState, usePageState } from '@/common/pageState'
import YesNoBadge from '@/components/common/YesNoBadge.vue'

export default defineComponent({
  props: {
    modelValue: [Boolean],
    pageState: {
      type: Object as PropType<PageState>,
      required: false
    }
  },  
  components: {
    YesNoBadge,
  },
  setup (props, context) {
    const ps = props.pageState ?? usePageState()

    const updateValue = (event: Event): void => {
      const value = (event.target as HTMLSelectElement).value
      if (value === 'undefined') {
        context.emit('update:modelValue', null)
      } else {
        context.emit('update:modelValue', value === 'true')
      }
    }

    return {
      ps,
      updateValue
    }
  }
})
