
import { defineComponent, PropType } from 'vue'
import { PageState, usePageState } from '@/common/pageState'

export default defineComponent({
  props: {
    modelValue: [String],
    pageState: {
      type: Object as PropType<PageState>,
      required: false
    },
    leftAlign: {
      type: Boolean,
      required: false,
      default: false
    }
  },  
  setup (props) {
    const ps = props.pageState ?? usePageState()

    return {
      ps
    }
  }
})
