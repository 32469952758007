import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return (_ctx.ps.isViewMode)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formattedValue()), 1))
    : (_ctx.ps.isEditMode)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control text-end",
            value: _ctx.formattedValue(),
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 40, _hoisted_3), [
            [_directive_maska, '##/##/####']
          ])
        ]))
      : _createCommentVNode("", true)
}