
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
      default: 'Filter for...'
    },
    modelValue: {
      type: String
    },
    filterId: {
      type: String,
      required: false,
      default: 'filterInput'
    }
  },
  setup (props) {
    const local = ref(props.modelValue)

    return {
      local
    }
  }
})
