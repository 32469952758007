
import { defineComponent, watch, onMounted, computed } from 'vue'
import { Modal } from 'bootstrap'

export default defineComponent({
  emits: ['update:modelValue'],
  components: {
  },
  props: {
    modelValue: Boolean,
    id: {
      type: String,
      required: true
    },
    sizeClass: {
      type: String,
      default: ''
    },
    title: String,
    okText: String,
    closeText: String,
    okCallback: Function,
    closeCallback: Function,
    okDisabled: {
      type: Boolean,
      default: false
    },
    okButtonClass: {
      type: String,
      default: 'btn-primary'
    },
    showOk: {
      type: Boolean,
      default: true
    },
    // this property will prevent the modal from closing when the user clicks outside of the modal
    noClose: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    let modal: any = null

    onMounted(() => {
      const el = document.getElementById(props.id)!
      modal = new Modal(el)

      el.addEventListener('hide.bs.modal', function (event) {
        emit('update:modelValue', false)
      })

      if (props.modelValue) {
        modal.show()
      }
    })

    watch(() => props.modelValue, (newVal, oldVal) => {
      // console.log(`new value: ${newVal} `)
      if (modal) {
        if (newVal) {
          modal.show()
        } else {
          modal.hide()
        }
      }
    })

    const closeFunction = async () => {
      if (typeof (props.closeCallback) === 'function') {
        await props.closeCallback()
      }
      emit('update:modelValue', false)
    }

    const getOkButtonClass = computed(() => {
      var classStr = `btn ${props.okButtonClass}`

      if (props.okDisabled) {
        classStr += ' btn-disabled'
      }

      return classStr
    })

    const okFunction = async () => {
      let result = false
      if (typeof (props.okCallback) === 'function') {
        result = await props.okCallback()
      } else {
        alert('no okCallback provided')
      }

      if (result) {
        modal.hide()
        emit('update:modelValue', false)
      }
    }
    return {
      okFunction,
      closeFunction,
      getOkButtonClass
    }
  }
})
