import {
  DocumentService, IEligibilityDeterminationResult, OptInRequestDto,
  ProgramApplicationDocumentDto,
  ProgramApplicationDocumentRequirementDto,
  ProgramApplicationDocumentRequirementStatus,
  ProgramApplicationDto,
  ProgramApplicationService,
  ProgramApplicationStatusHistoryDto,
  TransactionDto,
  TransactionService
} from '@/generated/applicant.api.clients'
import { createApi } from '@/plugin/Api'
import { defineStore } from 'pinia'
import { statusGroups } from '@/common/programApplication'

function requireAll (r:any) { r.keys().forEach(r) }
requireAll(require.context('pretty-file-icons/svg', true, /\.svg$/))

const service = new ProgramApplicationService(undefined, createApi(process.env.VUE_APP_APPLICANT_API_URL))
const transactionService = new TransactionService(undefined, createApi(process.env.VUE_APP_APPLICANT_API_URL))
const docService = new DocumentService(undefined, createApi(process.env.VUE_APP_APPLICANT_API_URL))
export const useApplicantApplicationStore = defineStore('applicant-application-store', {
  state: () => {
    return {
      programApplication: null as ProgramApplicationDto | null,
      documents: [] as ProgramApplicationDocumentDto[],
      transactions: [] as TransactionDto[],
      requirements: [] as ProgramApplicationDocumentRequirementDto[],
      additionalDocs: [] as ProgramApplicationDocumentRequirementDto[],
      probationAppealDocs: [] as ProgramApplicationDocumentRequirementDto[],
      history: [] as ProgramApplicationStatusHistoryDto[],
      waiveFreshmanSemesterOption: undefined as number | undefined,
      transcriptSource: undefined as number | undefined,
      eligibilityResult: null as IEligibilityDeterminationResult | null
    }
  },
  actions: {
    async loadProgramApplication (id: number) {
      this.programApplication = null
      this.transactions = []
      this.documents = []
      this.requirements = []
      this.additionalDocs = []
      this.probationAppealDocs = []

      this.history = []
      this.waiveFreshmanSemesterOption = undefined
      this.transcriptSource = undefined

      this.programApplication = await service.getProgramApplication(id)
      this.transactions = await transactionService.getProgramApplicationTransactions(id)
      this.history = await service.getProgramApplicationStatusHistory(id)
      this.eligibilityResult = await service.getEligibility(id)
      if (this.programApplication?.applicantProgramData?.waived_freshman_semester?.value) {
        this.waiveFreshmanSemesterOption = parseInt(this.programApplication.applicantProgramData.waived_freshman_semester.value)
      }

      this.transcriptSource = this.programApplication?.applicantProgramData?.freshman_forfeit_transcript_source?.value ? parseInt(this.programApplication?.applicantProgramData?.freshman_forfeit_transcript_source.value!) : undefined

      await this.refreshRequirements()
    },
    async refreshRequirements () {
      const reqs = await service.getProgramApplicationDocumentationRequirements(this.programApplication!.id)
      this.requirements = reqs.filter(r => r.documentRequirementId !== 0)
      this.additionalDocs = reqs.filter(r => r.documentRequirementId === 0 && r.isProbationAppealDocument === false)
      this.probationAppealDocs = reqs.filter(r => r.documentRequirementId === 0 && r.isProbationAppealDocument === true)
    },
    async refreshDocuments (id: number) {
      this.documents = await docService.getProgramApplicationDocuments(id)
    },
    async submitApplication () : Promise<boolean> {
      const id = this.programApplication?.id!
      const result = await service.submitProgramApplication(id)
      if (result) await this.loadProgramApplication(id)
      return result
    },
    async updateFreshmanSemesterWaiverDesignation (waive: number) : Promise<void> {
      const id = this.programApplication?.id!
      await service.applicantUpdateWaiveFreshmanSemesterDesignation(id, waive)
    },
    async updateFreshmanSemesterWaiverTranscriptSource (source: number) : Promise<void> {
      const id = this.programApplication?.id!
      await service.applicantUpdateWaiveFreshmanSemesterTranscriptSource(id, source)
    },
    async deleteDocument (id: string) {
      if (confirm('Are you sure you want to delete this document?')) {
        return await docService.deleteDocument(id)
          .then(async () => {
            await this.refreshRequirements()
          })
          .catch(() => {
            return false
          })
      }
      return Promise.resolve()
    },
    async updateSummerOptInStatus (optIn: boolean) : Promise<void> {
      const dto = new OptInRequestDto()
      dto.optIn = optIn
      dto.programApplicationId = this.programApplication?.id!
      await service.optInForSummerTerm(dto)
    },
  },
  getters: {
    getProgramApplicationSubmittedDate(): Date | undefined {
      // get earliest date from history where statusName is lowercase version of 'Submitted Application'
      return this.history.filter((h: ProgramApplicationStatusHistoryDto) => h.statusName?.toLowerCase() === 'submitted application').sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.date
    },
    isFafsaRequired(): boolean {
      const excludedProgramApplicationTypes = ['ROMS']
      return (this.programApplication?.applicantProgramData!.fafsa !== undefined) &&
        !excludedProgramApplicationTypes.includes(this.programApplication?.programAbbreviation!)
    },
    isFafsaReceived(): boolean {
      return (this.programApplication?.applicantProgramData!.fafsa.adminOverrideValue || this.programApplication?.applicantProgramData!.fafsa.value) === '1'
    },
    fafsaHasIssues(): boolean {
      return (this.programApplication?.applicantProgramData!.fafsa.adminOverrideValue || this.programApplication?.applicantProgramData!.fafsa.value) === '0'
    },
    fafsaHasExtendedInformation(): boolean {
      return this.fafsaHasIssues && (this.programApplication?.applicantProgramData!.fafsa_extended_status_information?.value || '').length > 0
    },
    getProgramApplicationCreatedDate(): Date | undefined {
      // get earliest date from history where statusName is lowercase version of 'Submitted Application'
      return this.history.filter((h: ProgramApplicationStatusHistoryDto) => h.statusName?.toLowerCase() === 'submitted application' || h.statusName?.toLowerCase() === 'draft').sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.date
    },
    getDocumentGroups(): any {
      return this.requirements.reduce((groups: any, requirement: ProgramApplicationDocumentRequirementDto) => {
        const group = requirement.requirementGroup || requirement.prompt!
        groups[group] = groups[group] || { items: [] }

        switch (requirement.fulfillmentStatus) {
          case ProgramApplicationDocumentRequirementStatus.NotCompleted:
            groups[group].items.push({ ...requirement, badge: 'badge bg-secondary', icon: 'fas fa-times-circle', text: 'Not Completed' })
            break
          case ProgramApplicationDocumentRequirementStatus.UnderReview:
            groups[group].items.push({ ...requirement, badge: 'badge badge-warning', icon: 'fas fa-hourglass-half', text: 'Under Review' })
            break
          case ProgramApplicationDocumentRequirementStatus.Completed:
            groups[group].items.push({ ...requirement, badge: 'badge badge-success', icon: 'fas fa-check-circle', text: 'Completed' })
            break
          case ProgramApplicationDocumentRequirementStatus.Rejected:
            groups[group].items.push({ ...requirement, badge: 'badge badge-danger', icon: 'fas fa-times-circle', text: 'Rejected' })
            break
        }

        groups[group].name = requirement.requirementGroup || requirement.prompt!

        // if any document in groups[group].items has a fulfillmentStatus of 0 then set the groups[group].status to 0
        // else if any document in groups[group].items has a fulfillmentStatus of 2 then set the groups[group].status to 2
        // else groups[group].status = 1
        groups[group].status = groups[group].items.some((item: ProgramApplicationDocumentRequirementDto) => item.fulfillmentStatus === ProgramApplicationDocumentRequirementStatus.Completed)
          ? ProgramApplicationDocumentRequirementStatus.Completed : groups[group].items.some((item: ProgramApplicationDocumentRequirementDto) => item.fulfillmentStatus === ProgramApplicationDocumentRequirementStatus.UnderReview)
            ? ProgramApplicationDocumentRequirementStatus.UnderReview : ProgramApplicationDocumentRequirementStatus.NotCompleted

        return groups
      }, {})
    },
    getRequiredDocuments(): any {
      return this.requirements.map(mapDocument)
    },
    getAdditionalDocuments(): any {
      return this.additionalDocs.map(mapDocument)
    },
    getProbationAppealDocuments(): any {
      return this.probationAppealDocs.map(mapDocument)
    },
    getDocumentUrl: (): Function => {
      return (applicantDocumentId: string): string => {
        return applicantDocumentId ? `${process.env.VUE_APP_APPLICANT_API_URL}/Document/${applicantDocumentId}/content` : ''
      }
    },
    getIconPath: (): Function => {
      const prettyFileIcons = require('pretty-file-icons')
      return (filename: string): string => {
        return require('pretty-file-icons/svg/' + prettyFileIcons.getIcon(filename) + '.svg')
      }
    },
    isDocumentUploadAllowedForApplication: (state): boolean => !statusGroups.uploadProhibited.includes(state.programApplication?.status || ''),
    hasLoanTrackingRecords: (state): boolean => (state.programApplication?.loanTrackings?.length || 0) > 0
  },
})
const mapDocument = (document: any) => {
  switch (document.fulfillmentStatus) {
    case ProgramApplicationDocumentRequirementStatus.NotCompleted:
      return { ...document, badge: 'badge bg-secondary', icon: 'fas fa-times-circle', text: 'Not Completed' }
    case ProgramApplicationDocumentRequirementStatus.UnderReview:
      return { ...document, badge: 'badge badge-warning', icon: 'fas fa-hourglass-half', text: 'Under Review' }
    case ProgramApplicationDocumentRequirementStatus.Completed:
      return { ...document, badge: 'badge badge-success', icon: 'fas fa-check-circle', text: 'Completed' }
    case ProgramApplicationDocumentRequirementStatus.Rejected:
      return { ...document, badge: 'badge badge-danger', icon: 'fas fa-times-circle', text: 'Rejected' }
  }
}

export type ApplicantApplicationStore = Omit<
    ReturnType<typeof useApplicantApplicationStore>,
    keyof ReturnType<typeof defineStore>
>
