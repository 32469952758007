
import { defineComponent, ref, Ref, watch, computed } from 'vue'
import Modal from '@/components/common/Modal.vue'
import { IdentityService, RequestChangeEmailAddressCommand } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { useToast } from 'vue-toast-notification'
import { EmailRegex } from '@/common/globals'
import { useReCaptcha } from 'vue-recaptcha-v3'

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const recaptcha = useReCaptcha()!

    const requestSubmitted = ref(false)
    const showModal: Ref<boolean> = ref(false)
    const errorResponse: Ref<string | null> = ref(null)
    const email: Ref<string> = ref('')
    const confirmEmail: Ref<string> = ref('')
    const toast = useToast()

    watch(() => props.modelValue, () => {
      requestSubmitted.value = false
      email.value = ''
      confirmEmail.value = ''
      errorResponse.value = null
      console.log(`props.modelValue changed to ${props.modelValue}`)
      showModal.value = props.modelValue
    })

    const service = new IdentityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
    const requestChangeEmailAddress = async () => {
      if (requestSubmitted.value) {
        showModal.value = false
      }

      await recaptcha.recaptchaLoaded()

      service.requestChangeEmailAddress(new RequestChangeEmailAddressCommand({
        newEmail: email.value,
        recaptcha: await recaptcha.executeRecaptcha('RequestChangeEmail')
      })).then(() => {
        requestSubmitted.value = true
        toast.success('Change of Email Address Requested', { position: 'top' })
      })
        .catch(err => {
          errorResponse.value = err.detail
          // toast.error(err.message, { duration: 0, position: 'top' })
        })
    }

    const isValid = computed(() => {
      var regexResult = EmailRegex.test(email.value)
      return regexResult && email.value === confirmEmail.value
    })

    watch(showModal, () => {
      emit('update:modelValue', showModal.value)
    })

    return {
      requestSubmitted,
      showModal,
      isValid,
      email,
      confirmEmail,
      requestChangeEmailAddress,
      errorResponse
    }
  }
})
