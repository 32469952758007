
import { defineComponent } from 'vue'
import FilterInput from '@/modules/portal/financialAid/components/FilterInput.vue'
import { useDashboardStore } from '../../store'
import ApplicantLinkColumnRenderer from '@/modules/portal/financialAid/components/ApplicantLinkColumnRenderer.vue'
import ApplicationLinkColumnRenderer from '@/modules/portal/financialAid/components/ApplicationLinkColumnRenderer.vue'
import { currencyRenderer } from '@/components/ag-grid/HtmlCellRenderers'
export default defineComponent({
  components: {
    FilterInput
  },
  setup () {
    const store = useDashboardStore()

    const columnDefs = [
      { headerName: 'Applicant', field: 'applicantName', sortable: true, filter: true, width: 200, cellRenderer: ApplicantLinkColumnRenderer, valueGetter: (params: any) : string => `${params.data.lastName}, ${params.data.firstName}` },      
      { field: 'collegeName', flex: 1 },
      { headerName: 'Program', field: 'degreeProgram', sortable: true, flex: 1, cellRenderer: ApplicationLinkColumnRenderer, cellRendererParams: (row: any) => { return { options: { programApplicationId: row.data.arhegLoanProgramApplicationId, linkText: `${row.data.degreeProgram}` } } } },    
      { field: 'currentStatus', flex: 1, sortable: true, filter: true },
      { field: 'amount', headerName: 'Loan Amount', type: 'rightAligned', width: 160, cellRenderer: currencyRenderer },
      { field: 'amountForgiven', type: 'rightAligned', width: 175, cellRenderer: currencyRenderer },
      { field: 'balance', type: 'rightAligned', width: 175, cellRenderer: currencyRenderer },
    ]

    return {
      columnDefs,
      store
    }
  }
})
