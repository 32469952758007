import { defineStore } from 'pinia'
import { IAcademicAwardLevelDto, IProgramDetailDto, IProgramDto, IProgramRelatedQuestionDto, ProgramRelatedQuestionDto, ProgramsService, UpdateMDSDEAReductionCommand } from '@/generated/financialAid.api.clients'
import { createApi } from '@/plugin/Api'

const service = new ProgramsService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))

export const useProgramStore = defineStore('program-store', {
  state: () => {
    return {
      programs: [] as IProgramDto[],
      program: null as unknown as IProgramDto,
      programDetails: [] as IProgramDetailDto[],
      programRelatedQuestions: [] as IProgramRelatedQuestionDto[],
      selectedProgramDetail: null as unknown as IProgramDetailDto,
      academicAwardLevels: [] as IAcademicAwardLevelDto[],
    }
  },
  actions: {
    async getPrograms () {
      this.programs = await service.get(null, undefined)
    },
    async getProgram (id: number) {
      this.program = await service.getProgramQuery(id, undefined)
      this.programRelatedQuestions = await service.getProgramRelatedQuestions(id, undefined)
      this.programDetails = await service.getProgramDetails(id)
    },
    async getAcademicAwardLevels (programId: number, programDetailId: number) {
      this.academicAwardLevels = await service.getAcademicAwardLevelsForProgramDetail(programDetailId, programId.toString(), undefined)
    },
    setSelectedProgramDetail (detail: IProgramDetailDto): void {
      this.selectedProgramDetail = detail
    },
    async updateMDSDEAReduction (schoolYear: number, reduction: number) {
      await service.updateMDSDEAReduction(new UpdateMDSDEAReductionCommand({ schoolYear: schoolYear, deaReduction: reduction }))
        .then((response) => {
          const detail = this.programDetails.find(p => p.applicationEndDate && p.applicationEndDate.getFullYear() === schoolYear)
          if (detail) {
            detail.deaReduction = response
          }
        })
    }
  }
})
// export class ProgramStore extends Store<IProgramStore> {
//   service = new ProgramsService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))

//   actions = {
//     getPrograms: async () => {
//       this.state.programs = await this.service.get(null, undefined)
//     },
//     getProgram: async (id: number) => {
//       this.state.program = await this.service.getProgramQuery(id, undefined)
//       this.state.programRelatedQuestions = await this.service.getProgramRelatedQuestions(id, undefined)
//       this.state.programDetails = await this.service.getProgramDetails(id)
//     },
//     setSelectedProgramDetail: (detail: IProgramDetailDto): void => {
//       this.state.selectedProgramDetail = detail
//     }
//   }

//   protected data (): IProgramStore {
//     return {
//       programs: [],
//       program: null,
//       programDetails: [],
//       programRelatedQuestions: [],
//       selectedProgramDetail: null
//     }
//   }
// }
