
import { defineComponent, PropType, computed } from 'vue'
import { toYesNoNoResponse } from '@/common/formatters/boolean'
export default defineComponent({
  props: {
    value: Boolean as PropType<boolean | null | undefined | string>,
    trueClass: {
      type: String,
      required: false,
      default: 'bg-success-100 text-success-900'
    },
    falseClass: {
      type: String,
      required: false,
      default: 'bg-danger-100 text-danger-900'
    },
    noResponseClass: {
      type: String,
      required: false,
      default: 'bg-secondary'
    },
    additionalText: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const displayText = computed(() => toYesNoNoResponse(Boolean(props.value)))
    const classOverrides = computed(() => {
      if (props.value === true || props.value === 'true') {
        return props.trueClass
      } else if (props.value === false || props.value === 'false') {
        return props.falseClass
      } else if (typeof props.value === 'undefined' || props.value === null) {
        return props.noResponseClass
      } else {
        // Handle unexpected values
        return props.noResponseClass
      }
    })

    return {
      displayText,
      classOverrides
    }
  }
})
