
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    className: {
      type: String,
      required: false
    },
    topRoute: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {      
    }
  }
})
