
import { usePageState, FormModeEnum } from '@/common/pageState'
import { ISecurityQuestionDto, IUserSecurityQuestionDto, MetadataService, SecurityService, UpdateSecurityQuestionsModel, UpdateUserSecurityQuestionsCommand } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'
import { createPinia } from 'pinia'
import { defineComponent, onBeforeMount, reactive } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { useToast } from 'vue-toast-notification'

export default defineComponent({
  setup() {
    const recaptcha = useReCaptcha()!
    const service = new SecurityService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
    const metadataService = new MetadataService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
    const toast = useToast()
    
    const state = reactive({
      mode: 'view',
      questionsUpdated: false,
      potentialSecurityQuestions: [] as ISecurityQuestionDto[] | null,
      localData: [] as { securityQuestionId: number, order: number, answer: string | undefined}[],
      currentSecurityQuestions: [] as IUserSecurityQuestionDto[] | null,
      selectedQuestions: [undefined, undefined, undefined] as (number|undefined)[],
      answers: [undefined, undefined, undefined] as (string|undefined)[]
    })
    const localPiniaInstance = createPinia()
    const pageState = usePageState(localPiniaInstance)
    
    pageState.startEditModeCallback = () => {
      state.localData = state.currentSecurityQuestions!.map(q => { return { securityQuestionId: q.securityQuestionId, answer: '', order: q.order } })
    }

    pageState.applyChangeCallback = async () => {
      console.log('save...')
      const payload: UpdateSecurityQuestionsModel[] = []
      state.localData?.forEach((d) => {
        payload.push(new UpdateSecurityQuestionsModel({ securityQuestionId: d.securityQuestionId, order: d.order, answer: d.answer }))
      })
      await recaptcha.recaptchaLoaded()

      await service.updateSecurityQuestions(new UpdateUserSecurityQuestionsCommand({ securityAnswers: payload, recaptcha: await recaptcha.executeRecaptcha('UpdateSecurityQuestions') }))
      state.questionsUpdated = true
      localStorage.setItem('SAMSSecurityWorkflowCompleted', '1.0')
      toast.success('Updated Security Questions', { position: 'top' })
    }

    const questionSubset = (set:number): ISecurityQuestionDto[] | undefined => state.potentialSecurityQuestions?.filter(m => (m.id % 3) === set)

    onBeforeMount(async () => {
      await recaptcha.recaptchaLoaded()

      state.potentialSecurityQuestions = await metadataService.getSecurityQuestions()
      state.currentSecurityQuestions = await service.getSecurityQuestions(await recaptcha.executeRecaptcha('GetSecurityQuestions'))
      state.localData = state.currentSecurityQuestions!.map(q => { return { securityQuestionId: q.securityQuestionId, answer: '', order: q.order } })
    })
    
    const isValid = () => {
      return state.localData.every(q => (q.answer || '').length > 2)
    }

    return {
      questionSubset,
      state,
      pageState,
      isValid,
      FormModeEnum
    }
  }
})
