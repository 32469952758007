
/* eslint-disable vue/no-setup-props-destructure */
import { defineComponent, onBeforeMount, ref, watchEffect } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: [Number, String],
      required: false
    },
    month: [Number, String],
    year: [Number, String],
    startYear: {
      type: Number,
      required: true
    },
    priorYears: {
      type: Number,
      required: true
    }
  },
  setup (props, { emit }) {
    const selectedMonth = ref(props.month)
    const selectedYear = ref(props.year)

    const yearOptions = [] as number[]
    const monthOptions = [] as { value: number, option: string}[]

    for (var i = props.startYear; i > props.startYear - props.priorYears; i--) {
      yearOptions.push(i)
    }

    for (var m = 0; m < 12; m++) {
      monthOptions.push({ value: m + 1, option: new Date(1, m, 1).toLocaleString('default', { month: 'long' }) })
    }

    onBeforeMount(() => {
      const parts = (props.modelValue?.toString() ?? '').split('/')
      if (parts.length === 2) {
        selectedMonth.value = Number(parts[0])
        selectedYear.value = Number(parts[1])
      }
    })

    watchEffect(() => {
      if (selectedMonth.value && selectedYear.value) {
        emit('update:modelValue', `${selectedMonth.value}/${selectedYear.value}`)
      }
    })
    return {
      selectedMonth,
      selectedYear,
      yearOptions,
      monthOptions
    }
  }
})
