import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white m-0 p-2" }
const _hoisted_2 = { id: "HeaderLogos" }
const _hoisted_3 = { class: "group-logo group-right" }
const _hoisted_4 = { class: "logo-item" }
const _hoisted_5 = { class: "logo-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_sams = _resolveComponent("logo-sams")!
  const _component_logo_adhe = _resolveComponent("logo-adhe")!
  const _component_logo_ade = _resolveComponent("logo-ade")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showSAMSLogo)
        ? (_openBlock(), _createBlock(_component_logo_sams, {
            key: 0,
            className: "logo-sams-size",
            "top-route": _ctx.topRoute
          }, null, 8, ["top-route"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.showADHELogo)
            ? (_openBlock(), _createBlock(_component_logo_adhe, {
                key: 0,
                className: "logo-adhe-size"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.showADELogo)
            ? (_openBlock(), _createBlock(_component_logo_ade, {
                key: 0,
                className: "logo-ade-size"
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}