import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "fas fa-circle-notch fa-spin fa-fw me-2"
}
const _hoisted_4 = {
  key: 1,
  class: "text-danger fas fa-exclamation-triangle fa-fw me-2"
}
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn",
    type: "button",
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("span", null, [
      true
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            (_ctx.state.status === 'pending')
              ? (_openBlock(), _createElementBlock("i", _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.state.status === 'error')
              ? (_openBlock(), _createElementBlock("i", _hoisted_4))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.buttonLabel)
        ? _renderSlot(_ctx.$slots, "default", { key: 1 })
        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.buttonLabel), 1))
    ])
  ], 8, _hoisted_1))
}