import { AccountApplicantDto, AccountManagementService, OrphanedAspNetUserDto } from '@/generated/financialAid.api.clients'
import { defineStore } from 'pinia'
import { createApi } from '@/plugin/Api'

const accountService = new AccountManagementService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))

export const useUserManagementStore = defineStore('user-management-store', {
  state: () => {
    return {
      userAccounts: [] as AccountApplicantDto[],
      orphanedAspNetUsers: [] as OrphanedAspNetUserDto[],
    }
  },
  actions: {
    async getUserAccounts(search: string) {
      this.userAccounts = await accountService.getApplicantAccountByCriteria(search)
    },
    async getOrphanedAccounts(search: string) {
      this.userAccounts = await accountService.getOrphanedTrafficByCriteria(search)
    },
    async getTOPAccounts(search: string) {
      this.userAccounts = await accountService.getTOPTrafficByCriteria(search)
    },
    async getPrivateSchoolAccounts(search: string) {
      this.userAccounts = await accountService.getPrivateSchoolTrafficByCriteria(search)
    },
    async getOrphanedAspNetUsers(search: string) {
      this.orphanedAspNetUsers = await accountService.getOrphanedAspNetUsers(search)
    },
    async deactivateAspNetUser(id: string) {
      await accountService.deactivateAspNetUser(id)
    },
  }
})
