import { defineStore } from 'pinia'

export enum FormModeEnum {
  View,
  Edit
}

export const usePageState = defineStore('page-state', {
  state: () => {
    return {
      // initialized: false,
      mode: FormModeEnum.View as FormModeEnum,
      localData: {} as Record<string, string | undefined>,
      initialLocalData: {} as Record<string, string | undefined>,
      startEditModeCallback: undefined as ((localData: Record<string, string | undefined>) => void) | undefined,
      applyChangeCallback: undefined as ((localData: Record<string, string | undefined>) => void) | undefined
    }
  },
  actions: {
    changeFormMode (newMode: FormModeEnum): void {
      this.mode = newMode
      if (this.mode === FormModeEnum.Edit) {
        this.startEditModeCallback!(this.localData)
      }
    },
    async initialize () {
      await this.startEditModeCallback!(this.localData)
      this.initialLocalData = { ...this.localData }
      // this.initialized = true
    },
    async cancelChanges () {
      await this.startEditModeCallback!(this.localData)
      this.mode = FormModeEnum.View
    },
    async applyChanges (newState: FormModeEnum = FormModeEnum.View) {
      await this.applyChangeCallback!(this.localData)
      this.mode = newState
    },
    registerCallbacks (startEditModeCallback: (localData: Record<string, string | undefined>) => void, applyChangeCallback: (localData: Record<string, string | undefined>) => void) {
      this.localData = {}
      this.startEditModeCallback = startEditModeCallback
      this.applyChangeCallback = applyChangeCallback
    }
  },
  getters: {
    isViewMode: (state) => state.mode === FormModeEnum.View,
    isEditMode: (state) => state.mode === FormModeEnum.Edit,
    isDirty: (state) => JSON.stringify(state.initialLocalData) !== JSON.stringify(state.localData)
  }
})

export type PageState = Omit<
    ReturnType<typeof usePageState>,
    keyof ReturnType<typeof defineStore>
>
