
import { computed, defineComponent, PropType, reactive, ref, watch } from 'vue'
import { PageState, usePageState } from '@/common/pageState'
import useVuelidate from '@vuelidate/core'
import { email } from '@vuelidate/validators'

// Define our validation interface
interface IEmailState {
  form: {
    email: string | undefined
  }
}

export default defineComponent({
  props: {
    modelValue: String,
    pageState: {
      type: Object as PropType<PageState>,
      required: false
    },
    leftAlign: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const ps = props.pageState ?? usePageState()

    // Reactive property to emit changes back to parent
    const selectValue = ref(props.modelValue)

    // Implement a validation state object
    const state: IEmailState = reactive({
      form: {
        email: ''
      }
    })

    // Define the validation rules
    const validationRules = computed(() => {
      return {
        form: {
          email: {
            email
          }
        }
      }
    })

    // Call vuelidate rules and state
    const v$ = useVuelidate(validationRules, state)
    const validateEmail = async () => {
      v$.value.$touch()
      return v$.value.$errors.length === 0
    }

    // Watch the props.modelValue coming down
    watch(async () => props.modelValue, async () => {
      state.form.email = props.modelValue
    }, { immediate: true })

    // If ps.isEditMode, assign our property for validation
    watch(async () => ps.isEditMode, async () => {
      state.form.email = props.modelValue
    }, { immediate: true })

    // Watch the property for changes to emit back to parent
    watch(async () => state.form.email, async () => {
      selectValue.value = state.form.email
      emit('update:modelValue', selectValue.value)
    }, { immediate: true })

    // Watch the validation changes to emit back to parent
    watch(async () => v$.value.$errors, async () => {
      let isValid = false
      if (v$.value.$errors.length === 0) {
        isValid = true
      }
      emit('validate', isValid)
    }, { immediate: true })

    return {
      v$,
      ps,
      state,
      validateEmail
    }
  }
})
