
/* eslint-disable */
/* vue/no-unused-components */
import { defineComponent, ref } from 'vue'
import { TicketStatusEnum } from '@/common/enumerations'

export default defineComponent({
  props: {
    items: {
      type: Object,
      required: true
    }
  },
  setup() {
    const ticketStatusName = (statusId: any) => {
      if (statusId == TicketStatusEnum.Draft) {
        return 'Open'
      } else if (statusId == TicketStatusEnum.InProgress) {
        return 'In Progress'
      } else if (statusId == TicketStatusEnum.Completed) {
        return 'Completed'
      } else {
        return 'Total'
      }
    }

    const badgeType = (statusId: any) => {
      if (statusId == TicketStatusEnum.Draft) {
        return 'badge-danger'
      } else if (statusId == TicketStatusEnum.InProgress) {
        return 'badge-warning'
      } else if (statusId == TicketStatusEnum.Completed) {
        return 'badge-success'
      } else {
        return 'badge-info'
      }
    }

    return {
      TicketStatusEnum,
      ticketStatusName,
      badgeType
    }
  }
})
