import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mr-2"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("span", null, [
    ((_ctx.params.options || {}).warning)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createElementVNode("i", {
            class: "fa fa-exclamation-triangle",
            title: _ctx.params.options.warning
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.params.data.applicantId && !_ctx.isIHE)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          title: (_ctx.params.options || {}).linkText || 'View Application',
          to: {name: 'FA/Applicant/Application', params: { applicantId: _ctx.params.data.applicantId || _ctx.params.options.applicantId, applicationId: _ctx.params.data.programApplicationId || _ctx.params.options.programApplicationId } }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString((_ctx.params.options || {}).linkText || 'View'), 1)
          ]),
          _: 1
        }, 8, ["title", "to"]))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString((_ctx.params.options || {}).linkText || 'View'), 1))
  ]))
}