
/* eslint-disable */
/* vue/no-unused-components */
import { defineComponent, reactive, watchEffect, ref, onMounted, inject } from 'vue'
import { TicketStatusEnum, UserTypeEnum } from '@/common/enumerations'
import { StoreKeys } from '@/store/StoreKeys'
import { UserStore } from '@/store/UserStore'
import TicketCategoryDropDown from '@/components/common/ticket/TicketCategoryDropDown.vue'
import TicketProgramDropDown from '@/components/common/ticket/TicketProgramDropDown.vue'
import TicketStatusDropDown from '@/components/common/ticket/TicketStatusDropDown.vue'
import TicketCounts from '@/components/common/ticket/TicketCounts.vue'
import TicketGridList from '@/components/common/ticket/TicketGridList.vue'
import LoadingPanel from '@/components/common/LoadingPanel.vue'

export default defineComponent({
  components: {
    TicketCategoryDropDown,
    TicketProgramDropDown,
    TicketStatusDropDown,
    TicketCounts,
    TicketGridList,
    LoadingPanel,
  },
  props: {
    ticketData: { type: Object, required: true },
    addTicketRouter: { type: Object },
    viewTicketRouter: { type: Object },
    userType: { type: String }
  },
  setup(props) {
    const userStore: UserStore = inject(StoreKeys.UserStoreKey)!
    const loading = ref(false)
    const begin = new Date()
    begin.setFullYear(begin.getFullYear() - 1)
    const end = new Date()
    const selectedTicketCategory = ref(null)
    const selectedProgram = ref(null)
    const selectedTicketStatus = ref('InProgress')
    const selectedRoleId = ref('')
    const selectedSupportStaffId = ref('')
    const searchKeyword = ref('')
    const showAssignedTicketsOnly = ref(false)

    let forTopUsers = false // top users (Admin/ADHE, Finance): show ticket counts, table filters
    if(props.userType === UserTypeEnum.Admin || props.userType === UserTypeEnum.Finance) {
      forTopUsers = true
    }

    const state = reactive({
      startDate: begin.toISOString().substring(0, 10),
      endDate: end.toISOString().substring(0, 10)
    })

    const actions = {
      onChangeTier: async () => {
        props.ticketData.assignment.selectedRoleId = selectedRoleId.value
        await props.ticketData.assignment.selectedSupportStaffId
      }
    }

    watchEffect(async () => {
      if(forTopUsers) { 
        loading.value = true

        let assignedUserId = selectedSupportStaffId.value
        if(showAssignedTicketsOnly.value) {
          assignedUserId = userStore.getState().userId!
        }

        await props.ticketData.getTicketsByCreatedDate(
          new Date(state.startDate),
          new Date(state.endDate),
          assignedUserId,
          selectedRoleId.value,
          selectedTicketStatus.value,
          selectedTicketCategory.value,
          searchKeyword.value,
          selectedProgram.value
        )
        loading.value = false
      }
    })

    onMounted(() => {
      if(forTopUsers) {
        props.ticketData.getTicketCounts()
      }
      else {
        // LOAD USER TICKET
        props.ticketData.getTicketByUser()
      }
    })

    return {
      loading,
      actions,
      state,
      TicketStatusEnum,
      UserTypeEnum,
      selectedTicketCategory,
      selectedProgram,
      selectedTicketStatus,
      selectedRoleId,
      selectedSupportStaffId,
      searchKeyword,
      forTopUsers,
      showAssignedTicketsOnly
    }
  }
})
