import { TermEnum } from '@/common/enumerations'

export const termDescription = (termCounter: number, calendarYear: number, academicYear: number, includeSummerTerm: boolean = false): string => {
  if (!includeSummerTerm) {
    // this is NOT the TermId. This assumes when we iterate terms we start with a 0-based counter, and that we increment each iteration, so the first (0 Term) is Spring, (1 Term) is Fall, (2 Term) is Spring again....
    // BUT also be aware when we push the term into the array, we invert that b/c Spring term is TermId 1, Fall Term is TermId 2. Yes, it is confusing.
    const t = termCounter % 2 === 0 ? 'Spring' : 'Fall'
    return `${t} ${calendarYear} (${t} Term of the ${academicYear - 1}/${academicYear} Academic Year)`
  }
  else {
    const terms = ['Spring', 'Summer', 'Fall']
    const t = terms[termCounter % terms.length]
    return `${t} ${calendarYear} (${t} Term of the ${academicYear - 1}/${academicYear} Academic Year)`
  }
}

const termId = (termCounter: number): number => {
  return (termCounter % 2) === 0 ? 1 : 2
}

export const useTermOptions = (includeCurrentTerm: boolean = false, includeSummerTerm: boolean = false,
  decisions?: { [index: string]: { id: number; name: string; start: string; end: string } }) => {
  const rightNow = new Date()
  const thisYear = rightNow.getFullYear()
  const terms: { calendarYear: number, academicYear: number, term: number, description: string, open: Date, close: Date }[] = []

  // if decision dates are not provided, use the default
  if (!decisions) {
    decisions = {
      spring: { id: TermEnum.Spring, name: 'Spring', start: '-10-01T00:00:00', end: '-01-31T23:59:59.999' },
      fall: { id: TermEnum.Fall, name: 'Fall', start: '-02-01T00:00:00', end: '-09-30T23:59:59.999' },
    }
  }
  if (includeSummerTerm) {
    decisions = {
      spring: { id: TermEnum.Spring, name: 'Spring', start: '-10-01T00:00:00', end: '-01-31T23:59:59.999' },
      summer: { id: TermEnum.Summer, name: 'Summer', start: '-02-01T00:00:00', end: '-05-31T23:59:59.999' },
      fall: { id: TermEnum.Fall, name: 'Fall', start: '-06-01T00:00:00', end: '-09-30T23:59:59.999' },
    }
  }

  // set the terms from default or provided decisions
  if (!includeSummerTerm) {
    for (let i = thisYear - 1; i <= thisYear + 2; i++) {
      terms.push({ calendarYear: i, academicYear: i, term: decisions.spring.id, description: termDescription(2, i, i, includeSummerTerm), open: new Date(`${i - 1 + decisions.spring.start}`), close: new Date(`${i + decisions.spring.end}`) })
      terms.push({ calendarYear: i, academicYear: i + 1, term: decisions.fall.id, description: termDescription(1, i, i + 1, includeSummerTerm), open: new Date(`${i + decisions.fall.start}`), close: new Date(`${i + decisions.fall.end}`) })
    }
  } else {
    for (let i = thisYear - 1; i <= thisYear + 2; i++) {
      terms.push({ calendarYear: i, academicYear: i, term: decisions.spring.id, description: termDescription(0, i, i, includeSummerTerm), open: new Date(`${i - 1 + decisions.spring.start}`), close: new Date(`${i + decisions.spring.end}`) })
      terms.push({ calendarYear: i, academicYear: i, term: decisions.summer.id, description: termDescription(1, i, i, includeSummerTerm), open: new Date(`${i + decisions.summer.start}`), close: new Date(`${i + decisions.summer.end}`) })
      terms.push({ calendarYear: i, academicYear: i + 1, term: decisions.fall.id, description: termDescription(2, i, i + 1, includeSummerTerm), open: new Date(`${i + decisions.fall.start}`), close: new Date(`${i + decisions.fall.end}`) }) }
  }
  const startIndex = terms.findIndex(t => (!includeCurrentTerm && t.open >= rightNow) || (t.open <= rightNow && t.close > rightNow))
  const endIndex = startIndex + 4
  return terms.slice(startIndex, endIndex)
}

export const useTermOptionsStartingFromDate = (startDate: Date, numberOfTerms: number) => {
  const feb1 = new Date(startDate.getFullYear(), 1, 1)
  const oct1 = new Date(startDate.getFullYear(), 9, 1)
  let year = startDate.getFullYear()
  let termCounter

  if (startDate <= feb1) {
    termCounter = 0
  } else if (startDate <= oct1) {
    termCounter = 1
  } else {
    termCounter = 2
    year++
  }

  const terms = []

  for (let i = 0; i < numberOfTerms; i++) {
    const academicYear = year + (termCounter % 2)
    terms.push({ calendarYear: year, academicYear, term: termId(termCounter), description: termDescription(termCounter, year, academicYear) })
    termCounter++
    if ((termCounter % 2) === 0) year++
  }

  return terms
}

export const appCookieExists = (): boolean => {
  return document.cookie.indexOf('.AspNet.RFC.SharedCookie') !== -1
}

export const isJSON = (value: any): boolean => {
  if (typeof value !== 'string') {
    return false
  }
  try {
    const json = JSON.parse(value)
    return (typeof json === 'object')
  }
  catch (error) {
    return false
  }
}

export const getBase64ImageFromURL = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx?.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = error => {
      reject(error)
    }
    img.src = url
  })
}

export const formatEnumMemberName = (memberName: string) => {
  return memberName.replace(/([A-Z])/g, ' $1').trim()
}
