import { ProgramApplicationDataResponse } from '@/generated/applicant.api.clients'
import { ApplicationResponse } from '@/generated/public.api.clients'

declare module '@/generated/applicant.api.clients' {

  interface ProgramApplicationDataResponse {
    getValue(): string | undefined,
    hasResponse(): boolean
  }
}

ProgramApplicationDataResponse.prototype.getValue = function (): string | undefined {
  return this.answerValue
}
ProgramApplicationDataResponse.prototype.hasResponse = function (): boolean {
  return typeof (this.answerValue) !== 'undefined' && this.answerValue !== ''
}

declare module '@/generated/public.api.clients' {
  interface ApplicationResponse {
    getValue(): string | undefined
  }
}

ApplicationResponse.prototype.getValue = function (): string | undefined {
  return this.answerValue
}
