
/* eslint-disable */
/* vue/no-unused-components */
import { defineComponent, ref } from 'vue'
import { ScholarshipTypeProgramEnum } from '@/common/enumerations'
import TicketCategoryDropDown from '@/components/common/ticket/TicketCategoryDropDown.vue'
import TicketProgramDropDown from '@/components/common/ticket/TicketProgramDropDown.vue'
import TicketDescriptionEditor from '@/components/common/ticket/TicketDescriptionEditor.vue'

export default defineComponent({
  components: {
    TicketCategoryDropDown,
    TicketProgramDropDown,
    TicketDescriptionEditor
  },
  props: {
    userType: { type: String },
    cancelRedirectRouter: { type: Object, default: () => ({ name: '' }) }
  },
  emits: ['onCreateTicket'],
  methods: {
    onCreateTicket() {
      // VALIDATIONS - BASED ON WHAT TICKET CATEGORY IS SELECTED
      const scholarshipTypeIds = Object.values(ScholarshipTypeProgramEnum)
      let isValid = false
      if (scholarshipTypeIds.includes(this.selectedCategory)) {
        if (
          this.selectedCategory !== null &&
          this.selectedCategory !== 0 &&
          this.selectedProgram != null &&
          this.selectedProgram !== 0 &&
          this.ticketDescription != null &&
          this.ticketDescription.length > 0
        ) {
          isValid = true
        }
      } else {
        if (this.selectedCategory != null && this.selectedCategory !== 0 && this.ticketDescription != null && this.ticketDescription.length > 0) {
          isValid = true
        }
      }

      this.$emit('onCreateTicket', isValid, this.selectedCategory, this.selectedProgram, this.ticketDescription)
    }
  },
  setup() {
    const scholarshipTypeIds = Object.values(ScholarshipTypeProgramEnum)
    const selectedCategory = ref(0)
    const selectedProgram = ref(0)
    const ticketDescription = ref('')

    const actions = {
      resetProgramValue: () => {
        if (!scholarshipTypeIds.includes(selectedCategory.value!)) {
          console.log('reset Program value')
          selectedProgram.value = 0
        }
      }
    }
    return {
      actions,
      selectedCategory,
      selectedProgram,
      ticketDescription
    }
  }
})
