import { IPage, ICondition, IQuestion, IConditionContainer } from './SearchAndApply'
import { InjectionKey, computed, ref, watch, Ref } from 'vue'
import '@/common/extensionMethods'
import { Router, useRouter } from 'vue-router'
import pages from '@/components/searchAndApply/pages.json'
import useVuelidate from '@vuelidate/core'
import { /* alpha, between, email, */ helpers, decimal, minLength, integer, required, sameAs } from '@vuelidate/validators'
import { ApplicationResponse } from '@/generated/public.api.clients'
import { ApplicantResponseTypeEnum, ProgramApplicationDataResponse } from '@/generated/applicant.api.clients'
import { useApplicationQuestionStore } from '@/store/ApplicationQuestionStore'

export enum ResponsePhaseEnum {
  Search = 1,
  Application = 2
}

const GS_LEADERSHIP_PAGE_REGEX = /5\d{3}$/

function questionsByResponsePhase(responses: Record<number, ApplicationResponse | ProgramApplicationDataResponse>, responsePhase: ResponsePhaseEnum): ProgramApplicationDataResponse[] {
  const result: ProgramApplicationDataResponse[] = []
  for (const [k, v] of Object.entries(responses)) {
    if (v instanceof ProgramApplicationDataResponse) {
      const c = v as ProgramApplicationDataResponse
      if (responsePhase === ResponsePhaseEnum.Search && c.responseType === ApplicantResponseTypeEnum.Search) {
        result.push(c)
      }
      else if (responsePhase === ResponsePhaseEnum.Application && (c.responseType === ApplicantResponseTypeEnum.Application || c.responseType === ApplicantResponseTypeEnum.SchoolActivity)) {
        result.push(c)
      }
    }
  }

  return result
}

const isValidACTScore = (value: string) => {
  const score = Number(value)
  return !helpers.req(value) || (score >= 0 && score <= 36)
}

function getValidators(question: IQuestion, questionStore: any, allResponses: Record<number, ApplicationResponse | ProgramApplicationDataResponse>): object | undefined {
  let v: any | undefined
  const optional = [1039, 1030]
  // const validateNumeric: any[] = []
  const validateInteger = [1014] /* zip */
  const validateDecimal = [1015] /* current loan balance */
  const validateACTScore = [1065, 1066, 1067, 1068, 1069, 1070]

  if (Number(question.id) < 5000) {
    const questionMetadata = questionStore.getQuestionById(Number(question.id))
    if (questionMetadata && !questionMetadata.questionText?.match(/if applicable/i) && !optional.includes(Number(question.id))) {
      v = Object.assign(v || {}, { answerValue: { answerValueRequired: required } })
    }

    if (validateInteger.includes(Number(question.id))) {
      if (v) {
        if (v.answerText) {
          v.answerText = Object.assign(v.answerText, { valueIsInteger: integer })
        } else {
          v.answerText = { valueIsInteger: integer }
        }
      }
    }

    if (validateDecimal.includes(Number(question.id))) {
      if (v) {
        if (v.answerText) {
          v.answerText = Object.assign(v.answerText, { valueIsDecimal: decimal })
        } else {
          v.answerText = { valueIsDecimal: decimal }
        }
      }
    }

    if (validateACTScore.includes(Number(question.id))) {
      v = v || { }
      if (v) {
        v.answerText = Object.assign(v.answerText || {}, { isValid: helpers.withMessage('ACT Scores must be a number between 0 and 36', isValidACTScore) })
      }
    }

    if (Number(question.id) === 1014) {
      if (v) {
        if (v.answerText) {
          v.answerText = Object.assign(v.answerText, { minLength: minLength(5) })
        } else {
          v.answerText = { minLength: minLength(5) }
        }
      }
    }

    if (Number(question.id) === 6) {
      if (v && allResponses[5] && allResponses[5].answerValue === '1') {
        if (v.answerValue) {
          v.answerValue = Object.assign(v.answerValue, { sameAsApplicationYear: helpers.withMessage('The graduation year must match the current school year when you indiciate you are a graduating senior', sameAs(questionStore.applicationYear.toString())) })
        }
      }
    }
  }

  return v
}

export class SearchPage {
  _questionStore = useApplicationQuestionStore()
  _completionCallback: Function
  _responses: Record<number, ApplicationResponse | ProgramApplicationDataResponse>
  _router: Router
  v$: any

  constructor(responses: Record<number, ApplicationResponse | ProgramApplicationDataResponse>, completionCallback: Function) {
    this._completionCallback = completionCallback
    this._responses = responses

    console.log('CTOR: Responses', this._responses)
    this._router = useRouter()

    watch(this.getters.rules, () => {
      // console.log('Watcher: Rules Changed', this._responses)
      this.v$ = useVuelidate(this.getters.rules.value as any, this._responses)
    }, { immediate: true })

    watch(this.state.currentPageId, () => {
      console.log('Page Changed')
      window.scrollTo(0, 0)
    }, { immediate: true })
  }

  state = {
    currentPageId: ref(undefined as unknown as string),
    pageStack: ref([] as string[]),
    assertions: ref({} as Record<string, string | undefined>),
    enabledQuestionFilter: ref([] as number[]),
    programs: ref([] as string[]),
    applicationYear: ref(0)
  }

  getters = {
    requiresGSLeadershipQuestions: computed((): boolean => this.state.programs.value.filter(p => p.startsWith('GS')).length > 0),
    requiresCollegeHistory: computed((): boolean => {
      return this.state.programs.value.some(p =>
        // ACSNT & ApplicationQuestions 3 requires college history.
        // {"Value":"3","Text":"I am currently attending or have previously attended college and have not earned a Bachelor's degree."}
        p.startsWith('ACSNT') && (this._responses[5]?.answerValue === '3')) ||
        // Or, 7001 & 7004 requires college history.
        // ApplicationQuestions 7001 - Are you currently enrolled as a first-time, full time freshman who graduated high school in the last 12 months?
        ((this._responses[7001]?.answerValue === '1') &&
        // ApplicationQuestions 7004 - Will you have earned at least twenty-seven (27) college credit hours by the end of the spring {current_year} semester?
          (this._responses[7004]?.answerValue === '1'))
    }),
    currentPage: computed((): IPage | undefined => pages.page.find(p => p.id === this.state.currentPageId.value)),

    sectionedPageQuestions: computed((): Array<{ sectionName: string, questions: IQuestion[] }> => {
      let filteredQuestions: IQuestion[] = []
      const sectionQuestions: Array<{ sectionName: string, questions: IQuestion[] }> = []

      if (!this.getters.currentPage.value) return sectionQuestions

      // The initial application 'page' in pages.json includes all possible questions that could be asked, for all programs. However, we only want to display the
      // questions germane to the programs being applied for. So, if we are on the application page (Page Id 1000, we filter the questions so we only include the ones we care about.
      // If we are on any other page, we display whatever questions are defined for that page in pages.json
      if (this.getters.currentPage.value.id === '1000' && this.state.enabledQuestionFilter.value.length) {
        filteredQuestions = [...this.getters.currentPage.value.question.filter((q) => this.state.enabledQuestionFilter.value.includes(Number(q.id)))]
      } else {
        filteredQuestions = [...this.getters.currentPage.value.question]
      }

      sectionQuestions.push({ sectionName: 'General', questions: filteredQuestions.filter((q: any) => this.conditionsMet(q)) })

      if (this.getters.currentPage.value.section) {
        this.getters.currentPage.value.section.forEach((s) => {
          sectionQuestions.push({ sectionName: s.title, questions: s.question.filter((q: any) => this.conditionsMet(q)) })
        })
      }

      console.log(`Encountered ${sectionQuestions.flatMap((s) => s.questions).length} on Page Id ${this.state.currentPageId.value}`)
      return sectionQuestions
    }),

    rules: computed(() => {
      const questionStore = this._questionStore
      const validators: Record<number, object> = {}
      this.getters.sectionedPageQuestions.value.forEach((section) => {
        section.questions.forEach((q) => {
          const v = getValidators(q, this._questionStore, this._responses)
          if (v) validators[Number(q.id)] = v
        })
      })

      console.log('computing validation rules as', validators)
      return validators
    }),

    hasMorePages: computed((): boolean => {
      if (!this.getters.currentPage.value) return false
      if (this.getters.currentPage.value.id === '1000' && this.getters.requiresCollegeHistory.value) return true
      if (this.getters.currentPage.value.id === '1000' && this.getters.requiresGSLeadershipQuestions.value) return true

      const r = this.getters.currentPage.value.routing

      if (r) {
        const routesWithNextPage = this.getters.currentPage.value.routing.filter((r: any) => r.nextpage)
        let hasMorePages = routesWithNextPage.length > 0

        if (hasMorePages) {
          for (const route of routesWithNextPage) {
            if (route.nextpage && GS_LEADERSHIP_PAGE_REGEX.test(route.nextpage) && !this.getters.requiresGSLeadershipQuestions.value) hasMorePages = false
          }
        }

        return hasMorePages
      }

      return false
    }),

    numberOfQuestionsCompleted: computed((): number => Object.keys(this._responses).filter(k => this.getters.projectedPathQuestionIds.value.includes(k)).length),
    numberOfQuestionsCompletedByPhase: (responsePhase: ResponsePhaseEnum) => computed((): number => {
      return questionsByResponsePhase(this._responses, responsePhase).filter(k => this.getters.projectedPathQuestionIds.value.includes(k.questionId.toString())).length
    }),

    nextPageId: computed((): string | undefined => {
      return this.getNextPageId(this.getters.currentPage.value)
    }),

    priorPageEnabled: computed((): boolean => this.state.pageStack.value.length > 0),
    priorPageId: computed((): string => {
      if (this.state.pageStack.value.length) {
        return this.state.pageStack.value[this.state.pageStack.value.length - 1]
      }
      return '0'
    }),

    projectedPathQuestionIds: computed((): string[] => {
      const pagesToCount = [...this.state.pageStack.value, this.state.currentPageId.value]
      let page: IPage | undefined = this.getters.currentPage.value
      let id: string | undefined = this.getNextPageId(page)
      const questionIds: string[] = []

      while (id) {
        if (Number(id) < 5000 || this.getters.requiresGSLeadershipQuestions.value) { // Page # 5000 and above are only used when a Governor's scholarship program is included
          pagesToCount.push(id)
          page = pages.page.find(p => p.id === id)!
          id = this.getNextPageId(page)
        } else {
          id = undefined
        }
      }

      const finalPages = new Set(pagesToCount)

      for (id of finalPages) {
        page = pages.page.find(p => p.id === id)!

        // by default, we will count the questions in the path as long as all conditions are met for the question
        let filteringLambda = (q: IQuestion) => this.conditionsMet(q)

        if (page.id === '1000') {
          // however, on page 1000, we will first only include the questions that are in the enabledQuestionsFilter, and then only those that have all conditions are met
          filteringLambda = (q: IQuestion) => this.state.enabledQuestionFilter.value.includes(Number(q.id)) && this.conditionsMet(q)
        }

        questionIds.push(...page!.question.filter((q) => filteringLambda(q)).map(q => q.id))
      }
      return questionIds
    }),

    projectedPathQuestions: computed((): number => {
      return this.getters.projectedPathQuestionIds.value.length
    }),

    approximatePercentComplete: computed((): number => Math.round((this.getters.numberOfQuestionsCompleted.value / this.getters.projectedPathQuestions.value) * 100)),
    approximatePercentCompleteByPhase: (responsePhase: ResponsePhaseEnum) => computed((): number => Math.round((this.getters.numberOfQuestionsCompletedByPhase(responsePhase).value / this.getters.projectedPathQuestions.value) * 100)),

    eligibilityErrors: computed(() => {
      const errors = []
      if (!['lawful', 'us citizen'].includes(this.state.assertions.value.citizenship || '')) { errors.push('You are not a US Citizen or lawful permanent resident.') }
      if (!(this.state.assertions.value.residency || this.state.assertions.value.parentalResidency)) {
        errors.push('You are not an Arkansas resident (and if under 21, neither is a parent or legal guardian.)')
      }

      return errors
    })
  }

  actions = {
    setEnabledQuestionsFilter: (enabledQuestions: number[]) => {
      this.state.enabledQuestionFilter.value = [...enabledQuestions]
    },
    setPrograms: (programs: string[]) => {
      this.state.programs.value = programs
    },
    setPage: (id: number) => {
      this.state.currentPageId.value = id.toString()
    },
    incrementPage: async () => {
      console.log(this.getters.rules.value)
      const result = await this.v$.value.$validate()

      if (result) {
        const canProceed = this.checkProceedability()

        if (!canProceed) {
          this._router.push({ name: 'Public/Search/Ineligible' })
        } else {
          this.state.pageStack.value.push(this.state.currentPageId.value)
          if (this.getters.nextPageId.value) {
            this.state.currentPageId.value = this.getters.nextPageId.value
          }
        }
      }
      else {
        // auto scroll to first validator-error
        const el = document.getElementsByClassName('validator-error')[0].parentElement
        el?.scrollIntoView({ block: 'center', inline: 'nearest' })
      }
    },

    decrementPage: () => {
      this.state.currentPageId.value = this.state.pageStack.value.pop()!
    },

    performCompletion: async (): Promise<any> => {
      const result = await this.v$.value.$validate()

      if (result) {
        return await this._completionCallback()
      }
      else {
        // auto scroll to first validator-error
        const el = document.getElementsByClassName('validator-error')[0].parentElement
        el?.scrollIntoView({ block: 'center', inline: 'nearest' })
      }
    },

    apply: async (): Promise<void> => {
    }
  }

  private checkProceedability = (): boolean => {
    let canProceed = true
    const assertions = { citizenship: undefined, age: undefined, residency: undefined, parentalResidency: undefined }
    const requirement = this.getters.currentPage.value?.requirement

    if (requirement) {
      canProceed = this.evaluateConditions(0, requirement, 'and', assertions)
      this.state.assertions.value = assertions
    }

    return canProceed
  }

  evaluateCondition = (condition: ICondition, assertions?: Record<string, string | undefined>): boolean => {
    let result = false
    try {
      const currentValue = this._responses[Number(condition.questionId)]?.getValue()

      if (condition.value) {
        result = currentValue?.toString() === condition.value.toString()
      } else if (condition.min || condition.max) {
        const min = isNaN(Number(condition.min)) ? -100000 : Number(condition.min)
        const max = isNaN(Number(condition.max)) ? 100000 : Number(condition.max)
        result = Number(currentValue) >= min && Number(currentValue) <= max
      }

      if (result && assertions && condition.asserts) {
        Object.keys(condition.asserts).forEach((k: string) => {
          assertions[k] = condition.asserts![k]
        })
      }

      // console.log(`Evaluating condition; currentValue: ${currentValue}: Result: ${result}`, condition)

      return result
    } catch (e: unknown) {
      return false
    }
  }

  evaluateConditions = (level: number, container: IConditionContainer, mode: string, assertions?: Record<string, string | undefined>): boolean => {
    // console.log(`level ${level} evaluation`, container)
    if (container.or) {
      // console.log('OR')
      for (const c of container.or) {
        const r = this.evaluateConditions(++level, c, 'and', assertions)
        if (r) {
          // if (c.asserts && assertions) {
          //   Object.keys(c.asserts).forEach((k:string) => {
          //     assertions[k] = c.asserts![k]
          //   })
          // }
          return r
        }
      }
      return false
    } else if (container.condition) {
      // console.log('CONDITION')
      let result = false
      for (const c of container.condition) {
        result = this.evaluateCondition(c, assertions)
        if (mode === 'or' && result) break
        if (mode === 'and' && !result) break
      }
      return result
    } else {
      // console.log('NO CONDITION/OR')
      return true
    }
  }

  private conditionsMet = (question: IConditionContainer): boolean => {
    // console.log(`Evaluating for question ${question.id}`)
    const result = this.evaluateConditions(0, question, 'and')
    // console.log(`EVALUATION FOR ${question.id}: ${result}`)
    return result
  }

  private getNextPageId = (currentPage: IPage | undefined): string | undefined => {
    if (!currentPage) return undefined

    if (currentPage.id === '1000') {
      if (this.getters.requiresCollegeHistory.value) {
        return '8000'
      }
    }

    for (const r of currentPage.routing) {
      if (r.nextpage && GS_LEADERSHIP_PAGE_REGEX.test(r.nextpage)) {
        if (this.getters.requiresGSLeadershipQuestions.value) {
          return r.nextpage
        }
      } else {
        if (!(r.condition) || this.evaluateConditions(0, r, 'and')) {
          return r.nextpage
        }
      }
    }
  }
}

export const SearchPageKey: InjectionKey<SearchPage> = Symbol('SearchPageKey')
