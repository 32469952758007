
import { defineComponent } from 'vue'
import { TicketStatusEnum } from '@/common/enumerations'
export default defineComponent({
  setup () {
    return {
      TicketStatusEnum
    }
  }
})
