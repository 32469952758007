import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_input = _resolveComponent("filter-input")!
  const _component_ag_grid = _resolveComponent("ag-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_filter_input, { class: "mb-2" }),
    _createVNode(_component_ag_grid, {
      style: {"width":"100%","height":"600px"},
      class: "ag-theme-alpine",
      columnDefs: _ctx.columnDefs,
      rowData: _ctx.store.loanRecords,
      pagination: "true"
    }, null, 8, ["columnDefs", "rowData"])
  ]))
}