import { ProgramApplicationDto, TransactionDto } from '@/generated/applicant.api.clients'
import { useMetadataStore } from '@/store/MetadataStore'

export const statusGroups = {
  draft: ['Draft'],
  submittedApplication: ['Submitted Application'],
  inProgress: ['Eligibility Pending', 'Awarded', 'Eligible', 'Pending Acceptance', 'Renewed', 'Pending Award', 'Pending Approval', 'Accepted', 'Probation'],
  completed: ['Dropped', 'Completed', 'Ineligible', 'Declined Award', 'Award Not Accepted', 'Deceased', 'Hold'],
  uploadProhibited: ['Dropped', 'Ineligible', 'Declined Award', 'Award Not Accepted', 'Deceased', 'Hold']
}

export const categoryMaps = {
  gs: new Map<number, string>([
    [0, 'Not Specified'],
    [1, 'Governor\'s Scholarship'],
    [2, 'Governor\'s Scholarship - Distinguished']
  ]),
  acs: new Map<number, string>([
    [0, 'Not Specified'],
    [5, 'Non-Traditional Earn-In']
  ])
}

export const classHelper = {
  badgeClass: (status: string) => {
    status = status ?? 'draft'
    switch (status.toLowerCase()) {
      case 'draft':
        return 'badge-warning'
      case 'submitted application':
        return 'badge-light'
      case 'awarded':
      case 'completed':
        return 'badge-success'
      case 'probation':
      case 'pending acceptance':
        return 'badge-warning'
      case 'award not accepted':
      case 'ineligible':
      case 'dropped':
        return 'badge-danger'
    }
  
    if (status.toLowerCase().includes('pending')) return 'badge-info'
  
    return 'bg-primary'
  },
  buttonClass: (status: string) => {
    status = status ?? 'draft'
    switch (status.toLowerCase()) {
      case 'draft':
        return 'badge-warning'
      case 'submitted application':
        return 'badge-light'
      case 'awarded':
      case 'completed':
        return 'badge-success'
      case 'probation':
      case 'pending acceptance':
        return 'badge-warning'
      case 'award not accepted':
      case 'ineligible':
      case 'dropped':
        return 'badge-danger'
    }
  
    if (status.toLowerCase().includes('pending')) return 'badge-info'
  
    return 'bg-primary text-white'
  }
}

export const governorsScholarPointsCalculator = (act: string | undefined, highSchoolGPA: string | undefined, classRank: string | undefined, classSize: string | undefined, leadership: string | undefined, nationalMeritScholar: string | number | undefined) => {
  const numAct = Number(act)
  const numGPA = Number(highSchoolGPA)
  const numClassRank = Number(classRank)
  const numClassSize = Number(classSize)
  const numLeadership = Number(leadership)
  const numNatMeritScholar = Number(nationalMeritScholar)
  
  let gpaPoints = (numGPA / 4) * 35
  if (gpaPoints > 35) {
    gpaPoints = 35
  }

  let actPoints = (numAct / 36) * 45
  if (numNatMeritScholar) actPoints = 45

  let classRankPoints = 0
  if (!(isNaN(numClassRank) || isNaN(numClassSize))) {
    classRankPoints = ((numClassSize - numClassRank) / numClassSize) * 10
  }
  const score = actPoints +
    gpaPoints +
    classRankPoints +
    (numLeadership / 75) * 10

  return !isNaN(score) ? Math.round(score * 10000) / 10000 : 0
}

export const extractFinalValueFromApplicantProgramData = (applicantProgramDatas: { [key: string]: {value: string | undefined, adminOverrideValue: string | undefined }; }, key: string): string | undefined => {
  return applicantProgramDatas[key]?.adminOverrideValue || applicantProgramDatas[key]?.value
}

const getDefaultDescription = (programApplication: ProgramApplicationDto) : string => { 
  switch (programApplication?.status) {
    case 'Draft': return 'Your application requires additional information and has not yet been submitted. Please supply the required documents and then submit your application.'
    case 'Submitted Application': return 'Your application has been submitted and is currently being reviewed. You will be notified when your application has been processed.'
    case 'Eligibility Pending': return 'We are either missing documentation to determine your eligibility OR you do not meet eligibility criteria based on documentation that has been submitted; if you have recently submitted a required document, please allow two weeks for processing.'
    case 'Eligible': return 'We have all the necessary information and your application meets the minimum eligibility requirements. We will notify you when we make awards based on availability.'
    case 'Ineligible': return 'We have all the necessary information and your application does not meet the minimum eligibility requirements.'
    case 'Pending Acceptance': return 'You have been selected for an award. Please accept or decline the award.'
    case 'Awarded': return 'You are awarded and your name will appear on the next roster sent to the college listed in your account.'
    case 'Declined Award': return 'You have declined the award.'
    case 'Dropped': return 'Your award has been dropped.'
    case 'Hold': return 'Your award has been placed on hold.'
    case 'Completed': return 'Your award has been completed.'
    case 'Renewed': return 'Your award has been renewed.'
    case 'Pending Approval': return 'Your award is pending approval.'
    case 'Accepted': return 'Your award has been accepted.'
    case 'Probation': return 'Your award has been put on probation.'
    case 'Award Not Accepted': return 'Your award has not been accepted.'
    case 'Pending Award': return 'Your award is pending.'
    case 'Deceased': return 'Your award has been marked as deceased.'
    default: return ''
  }
}

export const getStatusDescription = (programApplication: ProgramApplicationDto, transactions: TransactionDto[]): string => {
  const metadataStore = useMetadataStore()
  
  let description = null

  // specific descriptions
  switch (programApplication?.programAbbreviation) {
    case 'ACSNT':
    case 'ACSNT-CA':
      switch (programApplication?.status) {
        case 'Awarded': description = 'We have marked you as awarded and recently paid <b>{PAIDAMOUNT}</b> to {COLLEGENAME} for the {TERM}.'; break
      }; break
    case 'ACSO':
    case 'ACST':
      break
    case 'ARFUTURE': 
      switch (programApplication?.status) {
        case 'Eligible': description = `We have all the necessary information and your application meets the minimum eligibility requirements. The Arkansas Department of Higher Education (ADHE)
          will verify with the institution listed on your application that you are enrolled in an eligible degree program to determine if you can be considered for an Arkansas Future Grant. We
          will notify you when we maek awards based on availability.`; break
      }; break
    case 'ARHEG':
    case 'ARHEG-CHI':
    case 'ARHEG-DEN':
    case 'ARHEG-DENLOAN':
    case 'ARHEG-OPT':
    case 'ARHEG-OPTLOAN':
    case 'ARHEG-OST':
    case 'ARHEG-POD':
    case 'ARHEG-VET':
    case 'AWC':
    case 'ESEL':
    case 'GO':
    case 'GS':
    case 'LEO':
    case 'MDS':
    case 'MMF':
    case 'MTS':
    case 'NSLP':
    case 'SES':
    case 'STAR':
    case 'STEP':
      switch (programApplication?.status) {
        case 'Awarded': description = 'You are awarded and a check will be mailed to the lender specified on your application within 45 days.'
          break
        case 'Eligibility Pending': description = 'Your application is currently being reviewed for eligibility. Please check your alerts tab for any notifications regarding your application. You will be notified when your application has been processed.'
          break
      }; break
    case 'TOP':
      switch (programApplication?.status) {
        case 'Awarded': description = 'You are awarded and a check will be mailed to your address in the system within 45 days.'
          break
      }; break
    case 'WS':
  }

  // if description is blank, get the default description for this status
  description = description ?? getDefaultDescription(programApplication)
  
  // if description contains replacable tokens
  if (description?.includes('{') && description?.includes('}')) {
    const paidTransaction = transactions.filter(t => t.type === 'Award').sort((a, b) => b.transactionId - a.transactionId)[0]
    const paidCollege = metadataStore.colleges.find(c => c.id === paidTransaction?.collegeId)?.name

    if (paidTransaction) {
      description = description.replace('{PAIDAMOUNT}', `$${paidTransaction?.amount?.toFixed(2)?.toString() ?? '0.00'}`)
      description = description.replace('{COLLEGENAME}', paidCollege ?? '')
      description = description.replace('{TERM}', `${paidTransaction?.term!} ${paidTransaction?.year!}` ?? '')
    }
    else {
      description = getDefaultDescription(programApplication)
    }
  }

  return description
}
