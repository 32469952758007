import {
  ARHEGDashboardService,
  ARHEGLoanAdminService,
  ARHEGLoanPromissoryNoteStatusEnum,
  ARHEGLoanRecordDto,
  AddARHEGLoanConfigurationCommand,
  AddOrUpdateARHEGDentalCollegeTuitionRateCommand,
  CreateARHEGCertificationExportCommand,
  CreateARHEGInvoiceCommand,
  IARHEGApplicationDto,
  IARHEGGraduateDto,
  IARHEGGrantWarrantDto,
  IARHEGInvoicableProgramApplicationsDto,
  IARHEGInvoiceDto,
  IARHEGLoanConfigurationDto,
  UpdateARHEGLoanConfigurationCommand,
  CreateARHEGLoansWarrantCommand,
  ARHEGLoanPromissoryNotesAwaitingWarrantCreationDto,
  ARHEGLoanPromissoryNotesWithWarrantDto,
  ChangeARHEGLoanPromissoryNoteStatusCommand,
  IARHEGDentalLoanWorksheetItemDto,
  UpdateARHEGDentalAwardingWorksheetCommand,
  SetGraduateTrackingFormsPendingForYear,
  ARHEGInvoiceStatusEnum,
  AddProgramApplicationToExistingARHEGInvoiceCommand,
  AddProgramApplicationToNewARHEGInvoiceCommand,
  ARHEGLoanPromissoryNoteDto,
  IARHEGInvoiceByProgramApplicationDto,
  ARHEGApplicantLoanVoucherDto,
  ARHEGLoanVoucherStatus,
  SetLoanVoucherStatusCommand,
  OfferLoanToInterestedDentalApplicants
} from '@/generated/financialAid.api.clients'
import { createApi } from '@/plugin/Api'
import { defineStore } from 'pinia'

const service = new ARHEGDashboardService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))
const adminService = new ARHEGLoanAdminService(undefined, createApi(process.env.VUE_APP_ADMIN_API_URL))

export const useDashboardStore = defineStore('arheg-program-management-dashboard-store', {
  state: () => {
    return {
      applications: null as IARHEGApplicationDto[] | null,
      fetchPending: {
        applications: false,
      },
      cachedApplications: new Map() as Map<string, IARHEGApplicationDto[] | null | undefined>,
      arhegFirstYearApplicants: [] as IARHEGInvoicableProgramApplicationsDto[] | null,
      arhegReturningApplicants: [] as IARHEGInvoicableProgramApplicationsDto[] | null,
      invoices: [] as IARHEGInvoiceDto[] | null,
      invoicesByProgramApplication: [] as IARHEGInvoiceByProgramApplicationDto[] | null,
      graduates: null as IARHEGGraduateDto[] | null,
      loanRecords: null as ARHEGLoanRecordDto[] | null,
      promissoryNotes: [] as ARHEGLoanPromissoryNoteDto[],
      admin: {
        loanConfigurations: [] as IARHEGLoanConfigurationDto[],
        selectedLoanConfiguration: {} as IARHEGLoanConfigurationDto | undefined,
        awardingWorksheets: [] as IARHEGDentalLoanWorksheetItemDto[]
      },
      warrants: {
        grant: [] as IARHEGGrantWarrantDto[] | undefined
      },
      promissoryNotesAwaitingWarrantCreation: null as ARHEGLoanPromissoryNotesAwaitingWarrantCreationDto[] | null,
      promissoryNotesWithWarrants: null as ARHEGLoanPromissoryNotesWithWarrantDto[] | null,
      graduateTrackingFormYears: [] as number[] | null,
      loanVouchers: [] as ARHEGApplicantLoanVoucherDto[] | null,
      loanVoucherYears: [] as number[] | null,
      loanVoucherStatusAction: {
        id: undefined as number | undefined,
        isToggled: false as boolean,
        status: undefined as ARHEGLoanVoucherStatus | undefined
      }
    }
  },
  actions: {
    async loadDashboardData (): Promise<void> {
      /* Grab the statistics for this dashboard */
      Promise.resolve([this.getApplications(['Declined Award', 'Pending Acceptance', 'Pending Award', 'Awarded', 'Accepted', 'Submitted Application', 'Pending Eligibility', 'Eligibility Pending', 'Eligible', 'Draft', 'Completed'])])
    },
    async getApplications (status: string[]): Promise<void> {
      this.fetchPending.applications = true
      this.applications = await service.getApplications(status, null)
      console.log(`Loaded ${this.applications.length} for statuses`, status)
      this.fetchPending.applications = false
    },
    async onDemandLoadApplications (status: string): Promise<void> {
      console.log(`Loading applications for status: ${status}`)
      this.cachedApplications.set(status, null)
      this.cachedApplications.set(status, await service.getApplications([status], null))
    },
    filteredApplications (applicationStatus: string | null): IARHEGApplicationDto[] | undefined {
      console.log(`returning applications for status ${applicationStatus}`)
      return this.applications?.filter(a => a.statusName === applicationStatus)
    },
    onDemandCachedApplications (status: string): IARHEGApplicationDto[] | null {
      console.log(`Accessing cached applications for status: ${status}`)
      return this.cachedApplications.has(status) ? this.cachedApplications.get(status)! : null
    },
    async exportEligibleApplications (programYears: number[], collegeIds: (number | undefined)[]) {
      const cmd = new CreateARHEGCertificationExportCommand({
        programYears: programYears,
        collegeIds: collegeIds
      })
      return await service.exportArhegCertifications(cmd)
    },
    async createInvoice (invoiceNumber: string, year: number, termId: number, collegeId: number, arhegFieldId: number, newApplicantIds: number[], returningApplicantIds: number[]): Promise<string> {
      return await service.createArhegInvoice(new CreateARHEGInvoiceCommand({
        invoiceNumber: invoiceNumber,
        year: year,
        termId: termId,
        collegeId: collegeId,
        arhegFieldId: arhegFieldId,
        firstTimeProgramApplicationIds: newApplicantIds,
        returningProgramApplicationIds: returningApplicantIds
      }))
    },
    async loadARHEGApplicantsForCollege (collegeId: number, arhegFieldId: number): Promise<void> {
      this.arhegFirstYearApplicants = await service.getEligibleArhegInvoiceApplicants(collegeId, arhegFieldId)
      this.arhegReturningApplicants = await service.getReturningArhegInvoiceApplicants(collegeId, arhegFieldId)
    },
    async getInvoices(createdSince: Date | null, status: ARHEGInvoiceStatusEnum | null, collegeId: number | null): Promise<void> {
      try {
        const invoices = await service.getARHEGInvoicesQuery(createdSince, status, collegeId)
        this.invoices = invoices.sort((a, b) => {
          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getInvoicesByProgramApplicationId(programApplicationId: number | undefined): Promise<void> {
      try {
        if (!programApplicationId) return
        const invoices = await service.getARHEGInvoicesByProgramApplicationQuery(programApplicationId)
        this.invoicesByProgramApplication = invoices.sort((a: IARHEGInvoiceByProgramApplicationDto, b: IARHEGInvoiceByProgramApplicationDto) => {
          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getGraduates (): Promise<void> {
      this.graduates = await service.getGraduates()
    },
    async getLoanRecords (): Promise<void> {
      this.loanRecords = await service.getARHEGLoanRecords()
    },
    async getPromissoryNotes (): Promise<void> {
      this.promissoryNotes = await service.getARHEGLoanPromissoryNotes()
    },
    async getLoanConfigurations (): Promise<void> {
      this.admin.loanConfigurations = await adminService.getARHEGLoanConfigurations()
    },
    async createLoanConfiguration (year: number, srebSlotFee: number, availableFunds: number): Promise<void> {
      await adminService.createLoanConfiguration(new AddARHEGLoanConfigurationCommand({
        year,
        srebSlotFee,
        availableFunds
      }), year.toString())
    },
    async setSelectedLoanConfiguration(year: number) {
      this.admin.selectedLoanConfiguration = this.admin.loanConfigurations.find(c => c.year === year)
      this.admin.awardingWorksheets = await adminService.getAwardingWorksheets(year)
    },
    async updateLoanConfiguration (id: number, srebSlotFee: number, availableFunds: number, year: number) {
      await adminService.updateLoanConfiguration(new UpdateARHEGLoanConfigurationCommand({
        id,
        srebSlotFee,
        availableFunds
      }), year.toString())
    },
    async addOrUpdateTuitionRate (row: any): Promise<void> {
      const command = new AddOrUpdateARHEGDentalCollegeTuitionRateCommand()
      command.init({ ...row, ...{ loanConfigId: this.admin.selectedLoanConfiguration?.id || 0 } })

      await adminService.addOrUpdateCollegeTuitionRate(command, this.admin.selectedLoanConfiguration?.year.toString() || '0', row.collegeId.toString())
    },
    async updateAwardingWorksheet (row: any): Promise<void> {
      const command = new UpdateARHEGDentalAwardingWorksheetCommand()
      command.init({ ...row, ...{ loanConfigId: this.admin.selectedLoanConfiguration?.id || 0 } })

      await adminService.updateAwardingWorksheet(command, this.admin.selectedLoanConfiguration?.year.toString() || '0', row.collegeId.toString())
    },
    async getGrantWarrants (): Promise<void> {
      this.warrants.grant = await service.getARHEGGrantWarrants()
    },
    async sendDentalEligibilityNotifications (loanIds: number[]): Promise<void> {
      await service.changeARHEGLoanStatus(new ChangeARHEGLoanPromissoryNoteStatusCommand({
        ids: loanIds,
        status: ARHEGLoanPromissoryNoteStatusEnum.ApplicantNotifiedOfEligibility
      }))
        .then(async () => {
          await this.loadDashboardData()
          await this.getLoanRecords()
        })
    },
    async closeUnacknowledgedNotifications (loanIds: number[]): Promise<void> {
      await service.changeARHEGLoanStatus(new ChangeARHEGLoanPromissoryNoteStatusCommand({
        ids: loanIds,
        status: ARHEGLoanPromissoryNoteStatusEnum.Canceled
      }))
        .then(async () => {
          await this.loadDashboardData()
          await this.getLoanRecords()
        })
    },
    async offerLoanToInterestedDentalApplicants (year: number) : Promise<void> {      
      await service.offerLoanToInterestedDentalApplicants(new OfferLoanToInterestedDentalApplicants({ year }))
    },
    async sendOptometryLoanOfferNotifications (loanIds: number[]): Promise<void> {
      await service.changeARHEGLoanStatus(new ChangeARHEGLoanPromissoryNoteStatusCommand({
        ids: loanIds,
        status: ARHEGLoanPromissoryNoteStatusEnum.Offered
      }))
        .then(async () => {
          await this.loadDashboardData()
          await this.getLoanRecords()
        })
    },
    async getARHEGpromissoryNotesAwaitingWarrantCreation (): Promise<void> {
      this.promissoryNotesAwaitingWarrantCreation = await service.getARHEGPromissoryNotesAwaitingWarrantCreation()
    },
    async createARHEGLoanWarrant (collegeId: number, year: number): Promise<void> {
      await service.createARHEGLoanWarrant(new CreateARHEGLoansWarrantCommand({
        collegeId,
        year
      }))
    },
    async getARHEGpromissoryNotesWithWarrants (): Promise<void> {
      this.promissoryNotesWithWarrants = await service.getARHEPromissoryNotesWithWarrants()
    },
    async getARHEGGraduateTrackingFormYears (): Promise<void> {
      this.graduateTrackingFormYears = await service.getARHEGGraduateTrackingFormYears()
    },
    async sendARHEGGraduateTrackingFormNotifications (year: number): Promise<void> {
      const cmd = new SetGraduateTrackingFormsPendingForYear({ year })
      await service.setGraduateTrackingFormsPendingForYear(cmd, String(year))
        .then(async () => {
          await this.getGraduates()
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    async addProgramApplicationToExistingInvoice(arhegInvoiceId: string, programApplicationId: number, yearOfStudy: number, amount: number): Promise<void> {
      await service.addProgramApplicationToExistingARHEGInvoice(new AddProgramApplicationToExistingARHEGInvoiceCommand({
        programApplicationId,
        yearOfStudy,
        amount,
        arhegInvoiceId
      }))
    },
    async addProgramApplicationToNewInvoice(collegeId: number, year: number, termId: number, programApplicationId: number, yearOfStudy: number, amount: number, invoiceNumber: string): Promise<string> {
      return await service.addProgramApplicationToNewARHEGInvoice(new AddProgramApplicationToNewARHEGInvoiceCommand({
        programApplicationId,
        yearOfStudy,
        amount,
        arhegCollegeId: collegeId,
        termId,
        year,
        invoiceNumber
      }))
    },
    async getLoanVouchersForYear (year: number | null): Promise<void> {
      this.loanVouchers = await service.getLoanVouchersForYear(year)
    },
    async getLoanVoucherYears (): Promise<void> {
      this.loanVoucherYears = await service.getLoanVoucherYears()
    },
    async setLoanVoucherStatus (id: number, status: ARHEGLoanVoucherStatus): Promise<void> {
      const cmd = new SetLoanVoucherStatusCommand({ id, status })
      await service.setLoanVoucherStatus(cmd)
    },
    async toggleLoanVoucherStatus (id: number, status: ARHEGLoanVoucherStatus): Promise<void> {
      this.loanVoucherStatusAction.id = id
      this.loanVoucherStatusAction.isToggled = true
      this.loanVoucherStatusAction.status = status
    },
    async finishLoanVoucherStatusAction() {
      this.loanVoucherStatusAction.id = undefined
      this.loanVoucherStatusAction.isToggled = false
      this.loanVoucherStatusAction.status = undefined
    },
  },
  getters: {
    acceptedPromissoryNotes: (state: any): ARHEGLoanPromissoryNoteDto[] => {
      return state.promissoryNotes?.filter((r: ARHEGLoanPromissoryNoteDto) => (r.status === ARHEGLoanPromissoryNoteStatusEnum.Accepted) || (r.status === ARHEGLoanPromissoryNoteStatusEnum.Processed))
    },
    dentalLoanPromissoryNotes: (state: any): ARHEGLoanPromissoryNoteDto[] => {
      return state.promissoryNotes?.filter((r: ARHEGLoanPromissoryNoteDto) => r.degreeProgram === 'Dentistry')
    },
    optometryLoanPromissoryNotes: (state: any): ARHEGLoanPromissoryNoteDto[] => {
      return state.promissoryNotes?.filter((r:ARHEGLoanPromissoryNoteDto) => r.degreeProgram === 'Optometry')
    }
  }
})
