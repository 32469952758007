import { defineStore } from 'pinia'
import { ProgramApplicationDataResponse, ApplicationService, ApplicationDto, SubmitApplicationCommand, SaveApplicationCommand, ApplicantResponseTypeEnum, ApplicationQualifyingProgramInfoDto } from '@/generated/applicant.api.clients'
import { ProgramRelatedQuestionsDto, ApplicationQuestionsService, ApplicationService as PublicApplicationService } from '@/generated/public.api.clients'
import { createApi } from '@/plugin/Api'

const service = new ApplicationService(undefined, createApi(process.env.VUE_APP_APPLICANT_API_URL))
const publicService = new PublicApplicationService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))
const questionService = new ApplicationQuestionsService(undefined, createApi(process.env.VUE_APP_PUBLIC_API_URL))

const extractReponses = (responses: Record<string, ProgramApplicationDataResponse>, type: ApplicantResponseTypeEnum) => {
  const rsp = []
  for (const [k, v] of Object.entries(responses)) {
    if (v.responseType === type) {
      rsp.push(v)
    }
  }
  return rsp
}

export const useApplicationSubmissionStore = defineStore('application-submission-store', {
  state: () => {
    return {
      application: undefined as ApplicationDto | undefined,
      removedItems: [] as ApplicationQualifyingProgramInfoDto[],
      programQuestions: undefined as ProgramRelatedQuestionsDto | undefined,
      response: {} as Record<string, ProgramApplicationDataResponse>,
      priorColleges: [] as { id: number | undefined, name: string | undefined }[]
    }
  },
  actions: {
    leadershipModel(unformatted: string | undefined): { grades: number[], officerYears: number | undefined, leaderType: { text: string }[], officer: { text: string }[], additionalText: string | undefined } {
      return JSON.parse(unformatted || '')
    },

    async loadApplicationSubmission(id: number, accessToken: string | null) {
      /* load the application */
      this.application = await service.getApplication(id, accessToken)

      /* get the questions that pertain to the application */
      await this.getQuestionsForProgramsByAbbreviations(this.application.qualifyingPrograms!)
      
      /* hydrate any existing responses. Have to iterate the keys and set the items. Setting the entire response prperty directly breaks */
      for (const k in this.application.responses) {
        this.response[k] = this.application.responses[k]
      }
    },
    async preFinalizeApplication(id: number, accessToken: string | null) {
      /* load the application */
      const results = await service.preFinalizeApplication(id, accessToken)
      this.application = results.application!
      this.removedItems = results.removedItems!

      if (this.application.qualifyingPrograms?.length! > 0) {
        /* get the questions that pertain to the application */
        await this.getQuestionsForProgramsByAbbreviations(this.application.qualifyingPrograms!)
        await this.getPreviousApplicationResponses(['Application', 'SchoolActivity'])
        /* hydrate any existing responses. Have to iterate the keys and set the items. Setting the entire response prperty directly breaks */
        for (const k in this.application.responses) {
          this.response[k] = this.application.responses[k]
        }
      }
    },
    async getQuestionsForProgramsByAbbreviations(abbreviations: string[]): Promise<void> {
      this.programQuestions = await questionService.getQuestionsForPrograms(abbreviations)
    },
    async getPreviousApplicationResponses (responseType: string[]): Promise<void> {
      const previousResponses = await publicService.getPreviousApplicationResponses(responseType)
      for (const r in previousResponses) {
        previousResponses[r].id = 0
        this.response[r] = new ProgramApplicationDataResponse({ ...previousResponses[r] })
      }
    },
    async setProgramApplicationResponse(id: number | undefined, questionId: number, response: ProgramApplicationDataResponse) {
      console.log(`Setting response on id: ${id}`)
      if (response.hasResponse()) {
        this.response[questionId] = response
      } else {
        delete this.response[questionId]
      }
    },
    async setProgramApplicationExtendedResponse(questionId: number, response: ProgramApplicationDataResponse) {
      if (response.hasResponse()) {
        // if a response is already in the collection, just update the answerValue
        if (this.response[questionId]) {
          this.response[questionId].answerValue = response.answerValue
        } else {
          // otherwise add the response to the collection
          this.response[questionId] = response
        }
      } else {
        delete this.response[questionId]
      }
    },
    updateCollegeHistoryResponse() {
      this.response[1036] = new ProgramApplicationDataResponse({
        id: undefined,
        responseType: ApplicantResponseTypeEnum.Application,
        questionId: 1036,
        answerValue: undefined,
        answerText: JSON.stringify(this.priorColleges)
      })
    },
    async submitApplication() {
      await service.submitApplication(new SubmitApplicationCommand({
        applicationId: this.application!.id,
        applicantId: undefined
      }))
    },
    async saveApplication() {
      const responseQuestionIds = Object.keys(this.response)
      responseQuestionIds.forEach(r => {
        console.log(r)
        console.log(this.response[r])
        if ((parseInt(r) < 5000 || parseInt(r) > 6999) && this.response[r].responseType !== ApplicantResponseTypeEnum.Search && !(this.combinedQuestions.includes(parseInt(r)) || r === '1036')) {
          delete this.response[r]
        }
        else if ((parseInt(r) >= 5000 && parseInt(r) <= 6999) && (!this.programQuestions?.programQuestions!.GSD || this.programQuestions?.programQuestions!.GS)) {
          delete this.response[r]
        }
      })

      service.saveApplication(new SaveApplicationCommand({
        applicationId: this.application!.id,
        responses: this.response,
        applicantId: undefined
      }))
    },
    addCollege() {
      this.priorColleges.push({ id: undefined, name: undefined })
    },
    removeCollege(index: number) {
      this.priorColleges.splice(index, 1)
    }
  },
  getters: {
    isGovernorsScholarQualifier: (state): boolean => (state.application?.qualifyingPrograms?.includes('GS') || state.application?.qualifyingPrograms?.includes('GSD')) || false,
    searchResponses: (state) => {
      return extractReponses(state.response, ApplicantResponseTypeEnum.Search)
    },
    applicationResponses: (state) => {
      return extractReponses(state.response, ApplicantResponseTypeEnum.Application)
    },
    schoolActivityResponses: (state) => {
      return extractReponses(state.response, ApplicantResponseTypeEnum.SchoolActivity)
    },
    combinedQuestions: (state): number[] => {
      let questionIds: number[] = []
      for (const key in state.programQuestions?.programQuestions) {
        questionIds = questionIds.concat(state.programQuestions?.programQuestions[key]!)
      }
      return [...new Set(questionIds)]
    },
    isSAMSFormattedActivityQuestions() {
      // if we can parse all answers to json, it is SAMS formatted
      for (const q of this.schoolActivityResponses) {
        try {
          JSON.parse(q?.answerValue || '')
        } catch {
          return false
        }
      }
      return true
    }
  }
})
